import React from "react";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import LoginMenu from "../auth/loginMenu";

function Header(props) {
  return (
    <header>
      <Navbar fixed="top" bg="primary" variant="dark" className="border-bottom">
        <Navbar.Brand href="/">bpviz</Navbar.Brand>
        <Nav className="mr-auto">
          {/* <Nav.Link href="/visualizer">Visualizer</Nav.Link> */}
          <NavDropdown title="Menu" id="basic-nav-dropdown">
            <NavDropdown.Item href="/visualizer">Visualizer</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/contact">Contact</NavDropdown.Item>
            <NavDropdown.Item href="/privacy-policy">
              Privacy Policy
            </NavDropdown.Item>
            <NavDropdown.Item href="/terms-of-service">
              Terms of Service
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <LoginMenu setActiveNode={props.setActiveNode} />
      </Navbar>
    </header>
  );
}

export default Header;
