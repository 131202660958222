import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Plus from "../icons/plus";
import Minus from "../icons/minus";

function LinkSpacingBtnGroup(props) {
  function addSpacing() {
    return props.setLinkSpacing(props.linkSpacing + 10);
  }
  function subtractSpacing() {
    return props.setLinkSpacing(props.linkSpacing - 10);
  }
  return (
    <ButtonGroup
      aria-label="Link Spacing Buttons"
      className="d-inline-block align-top mx-1 rounded border border-primary"
    >
      <Button
        variant="outline-primary"
        className="border-0 btn-sm"
        onClick={() => subtractSpacing()}
      >
        <Minus />
      </Button>
      <Button
        variant="outline-primary"
        className="border-0 btn-sm"
        onClick={() => addSpacing()}
      >
        <Plus />
      </Button>
    </ButtonGroup>
  );
}

export default LinkSpacingBtnGroup;
