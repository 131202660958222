import React from "react";
import Form from "react-bootstrap/Form";

function ObjectNameInput(props) {
  const watchName = props.watch("itemname", "");
  const errMsgTextStyle = () => {
    if (watchName.length > 50) {
      return "text-danger";
    }
  };
  return (
    <Form.Group controlId="" className="mx-2">
      <Form.Label>Name</Form.Label>
      <Form.Control
        name="itemname"
        type="text"
        placeholder="Enter Object Name"
        ref={props.regRef}
      />
      <Form.Text className={errMsgTextStyle()}>
        {watchName.length + "/50"}
      </Form.Text>
      <Form.Control
        className="d-none"
        name="itemtype"
        type="text"
        defaultValue="object"
        ref={props.regRef}
      />
    </Form.Group>
  );
}

export default ObjectNameInput;
