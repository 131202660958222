import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

function IsolateProcessAction(props) {
  const [isolated, setIsolated] = useState("Add to Isolation");
  let isolatedProcesses = [...props.isoActiveProcess];
  //If process currently in isolated processes, display accordingly
  useEffect(() => {
    if (isolatedProcesses.includes(props.process)) {
      setIsolated("Remove from Isolation");
    }
  }, [isolatedProcesses]);

  const handleIsolate = (item) => {
    if (isolated === "Remove from Isolation") {
      const removedProcess = isolatedProcesses.filter(
        (process) => process !== item
      );
      setIsolated("Add to Isolation");
      props.setIsoActiveProcess(removedProcess);
    }
    if (isolated === "Add to Isolation") {
      isolatedProcesses.push(item);
      setIsolated("Remove from Isolation");
      props.setIsoActiveProcess(isolatedProcesses);
    }
  };
  return (
    <Dropdown.Item onClick={() => handleIsolate(props.process)}>
      {isolated}
    </Dropdown.Item>
  );
}

export default IsolateProcessAction;
