import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AddActivityForm from "../form/activityForm";
import AddObjectForm from "../form/objectForm";
import AddRoleForm from "../form/roleForm";

function ControlledTabs(props) {
  const [key, setKey] = useState("activity");
  const dot = {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <Tabs
      id="controlled-tab-example"
      className="justify-content-center"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab
        eventKey="activity"
        title={
          <span>
            Activity{"  "}
            <i className="bg-activity" style={dot} />
          </span>
        }
      >
        <AddActivityForm
          processes={props.processes}
          activities={props.activities}
          setActivities={props.setActivities}
          objects={props.objects}
          nodeData={props.nodeData}
          roles={props.roles}
          setRoles={props.setRoles}
          setNodeData={props.setNodeData}
          linkData={props.linkData}
          setLinkData={props.setLinkData}
          activeNode={props.activeNode}
          setActiveNode={props.setActiveNode}
          linkDataDetail={props.linkDataDetail}
          setLinkDataDetail={props.setLinkDataDetail}
        />
      </Tab>
      <Tab
        eventKey="object"
        title={
          <span>
            Object{"  "}
            <i className="bg-object" style={dot} />
          </span>
        }
      >
        <AddObjectForm
          objects={props.objects}
          setObjects={props.setObjects}
          nodeData={props.nodeData}
          setNodeData={props.setNodeData}
          activities={props.activities}
          linkData={props.linkData}
          setLinkData={props.setLinkData}
          setLinkDataDetail={props.setLinkDataDetail}
        />
      </Tab>
      <Tab
        eventKey="role"
        title={
          <span>
            Role{"  "}
            <i className="bg-role" style={dot} />
          </span>
        }
      >
        <AddRoleForm
          roles={props.roles}
          setRoles={props.setRoles}
          nodeData={props.nodeData}
          setNodeData={props.setNodeData}
          activities={props.activities}
          linkData={props.linkData}
          setLinkData={props.setLinkData}
          setLinkDataDetail={props.setLinkDataDetail}
        />
      </Tab>
    </Tabs>
  );
}

export default ControlledTabs;
