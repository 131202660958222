import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import { Redirect } from "react-router-dom";
import Register from "../pages/register";

function RegisterRoutes(props) {
  const { isAuthenticated, setAuth } = useContext(AuthContext);

  return !isAuthenticated ? (
    <Register {...props} setAuth={setAuth} />
  ) : (
    <Redirect to="/login" />
  );
}

export default RegisterRoutes;
