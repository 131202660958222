import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import { Redirect } from "react-router-dom";
import UserDashboard from "../pages/userDashboard";

function UserDashboardRoutes(props) {
  const { isAuthenticated, setAuth } = useContext(AuthContext);

  return isAuthenticated ? (
    <UserDashboard {...props} setAuth={setAuth} />
  ) : (
    <Redirect to="/login" />
  );
}

export default UserDashboardRoutes;
