import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./components/pages/home";
import About from "./components/pages/about";
import Visualizer from "./components/pages/visualizer";
import PrivacyPolicy from "./components/pages/privacyPolicy";
import TermsOfService from "./components/pages/termsOfService";
import Contact from "./components/pages/contact";
import MessageSent from "./components/pages/messageSent";
import Error404 from "./components/pages/error404";
import PublicShared from "./components/pages/publicShared";
import AuthContextProvider from "./components/context/authContextProvider";
import PmContextProvider from "./components/context/pmContextProvider";
import RegisterRoutes from "./components/routing/registerRoutes";
import LoginRoutes from "./components/routing/loginRoutes";
import UserDashboardRoutes from "./components/routing/userDashboardRoutes";

function App() {
  return (
    <AuthContextProvider>
      <div className="App h-100 ">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <PmContextProvider>
                <HomePage {...props} />{" "}
              </PmContextProvider>
            )}
          />
          {/* <Switch> */}
          {/* <Route exact path="/about" component={About} /> */}
          <Route
            exact
            path="/public-shared/:network_id"
            component={PublicShared}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-service" component={TermsOfService} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/message-sent" component={MessageSent} />
          <Route
            exact
            path="/visualizer"
            render={(props) => (
              <PmContextProvider>
                <Visualizer />
              </PmContextProvider>
            )}
          />
          <Route
            exact
            path="/register"
            render={(props) => <RegisterRoutes {...props} />}
          />
          <Route
            exact
            path="/login"
            render={(props) => <LoginRoutes {...props} />}
          />
          <Route
            exact
            path="/user-dashboard"
            render={(props) => (
              <PmContextProvider>
                <UserDashboardRoutes {...props} />
              </PmContextProvider>
            )}
          />
          <Route exact path="/*" component={Error404} />
        </Switch>
      </div>
    </AuthContextProvider>
  );
}

export default App;
