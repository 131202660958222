import React from "react";
import Button from "react-bootstrap/Button";

function LinkCardEditBtn(props) {
  if (props.display === true) {
    return (
      <Button
        variant="outline-primary"
        className="mx-1 btn-sm"
        onClick={() => props.setLinkEditMode(true)}
      >
        Edit
      </Button>
    );
  } else if (!props.display) {
    return null;
  }
}

export default LinkCardEditBtn;
