import React, { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import Dropdown from "react-bootstrap/Dropdown";
import { useForm } from "react-hook-form";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function EditProcessForm(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const {
    loading,
    processes,
    setProcesses,
    getProcesses,
    setNodeData,
  } = useContext(PmContext);
  const { register, handleSubmit, watch, errors } = useForm();
  const [errMsgs, setErrMsgs] = useState([]);
  const nodeData = props.nodeData;
  const watchName = watch("processname", props.process);
  const errMsgTextStyle = () => {
    if (watchName.length > 50) {
      return "text-danger";
    }
  };

  async function onSubmit(data) {
    if (!isAuthenticated) {
      //Update in process prop of main node list
      let newNodeList = [...nodeData];
      newNodeList.forEach((node, index) => {
        if (node.process.label === data.oldprocessname) {
          newNodeList[index].process.label = data.processname;
        }
      });
      await setNodeData(newNodeList);

      //Update active processes if needed
      let newActiveProcess = props.activeProcess;
      newActiveProcess.forEach((process, index) => {
        if (process === data.oldprocessname) {
          newActiveProcess[index] = data.processname;
        }
      });
      await props.setActiveProcess(newActiveProcess);

      //Update process in active node if needed
      let newActiveNode = props.activeNode;
      if (newActiveNode.process === data.oldprocessname) {
        newActiveNode.process = data.processname;
      }
      await props.setActiveNode(newActiveNode);

      //Update isolated processes if needed
      let newIsoProcess = props.isoActiveProcess;
      newIsoProcess.forEach((process, index) => {
        if (process === data.oldprocessname) {
          newIsoProcess[index] = data.processname;
        }
      });
      await props.setIsoActiveProcess(newIsoProcess);

      //Update in main process list
      let newProcessList = [...processes];
      newProcessList[data.processindex].processname = data.processname;
      newProcessList[data.processindex].process_name = data.processname;
      await setProcesses(newProcessList);
      await props.setEditMode(false);
    } else {
      setErrMsgs();
      //API call to update process in db
      const apiOptions = {
        url: "process-model/update-process",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { process_id: data.processid, process_name: data.processname },
      };
      let apiErr = 0;
      await axios(apiOptions)
        .then((response) => {
          const resData = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          setErrMsgs(error.response.data[0].msg);
          apiErr = 1;
        });
      if (apiErr === 1) return;
      //Update in process prop of main node list
      let newNodeList = [...nodeData];
      newNodeList.forEach((node, index) => {
        //   if (
        //     node.process !== null &&
        //     node.process.label === data.oldprocessname
        //   ) {
        //     newNodeList[index].process.label = data.processname;
        //   } else if (node.process === data.oldprocessname) {
        //     newNodeList[index].process = data.processname;
        //   }
        // });
        // await props.setNodeData(newNodeList);

        if (node.process === data.oldprocessname) {
          newNodeList[index].process = data.processname;
        }
      });
      await props.setNodeData(newNodeList);

      //Update active processes if needed
      let newActiveProcess = props.activeProcess;
      newActiveProcess.forEach((process, index) => {
        if (process === data.oldprocessname) {
          newActiveProcess[index] = data.processname;
        }
      });
      await props.setActiveProcess(newActiveProcess);

      //Update process in active node if needed
      let newActiveNode = props.activeNode;
      if (newActiveNode.process === data.oldprocessname) {
        newActiveNode.process = data.processname;
      }
      props.setActiveNode(newActiveNode);

      //Update isolated processes if needed
      let newIsoProcess = props.isoActiveProcess;
      newIsoProcess.forEach((process, index) => {
        if (process === data.oldprocessname) {
          newIsoProcess[index] = data.processname;
        }
      });
      await props.setIsoActiveProcess(newIsoProcess);

      //Update in main process list
      let newProcessList = [...props.processes];
      newProcessList[data.processindex].processname = data.processname;
      await setProcesses(newProcessList);
      await props.setEditMode(false);
      await getProcesses();
    }
  }

  if (!props.editMode.inEdit) {
    return (
      <Dropdown.Item
        onClick={() =>
          props.setEditMode({
            inEdit: true,
            process: props.process,
          })
        }
      >
        Edit
      </Dropdown.Item>
    );
  } else {
    return (
      <Card>
        <Card.Body>
          <Form id="process-edit-form" onSubmit={handleSubmit(onSubmit)}>
            <Card.Subtitle className="mb-2 text-muted text-left">
              <Form.Label>Process Name</Form.Label>
              <Form.Control
                required
                name="processname"
                type="text"
                defaultValue={props.process}
                ref={register({
                  minLength: 1,
                  maxLength: 50,
                })}
              />
              <Form.Text className={errMsgTextStyle()}>
                {watchName.length + "/50"}
              </Form.Text>
              <Form.Control
                className="d-none"
                name="processindex"
                type="text"
                defaultValue={props.processIndex}
                ref={register}
              />
              <Form.Control
                className="d-none"
                name="processid"
                type="number"
                defaultValue={props.processId}
                ref={register}
              />
              <Form.Control
                className="d-none"
                name="oldprocessname"
                type="text"
                defaultValue={props.process}
                ref={register}
              />
            </Card.Subtitle>
            <Form.Text className="text-danger">{errMsgs}</Form.Text>
            <Button
              variant="outline-primary"
              type="submit"
              className="mx-1 btn-sm"
            >
              Save
            </Button>
            <Button
              variant="outline-danger"
              className="mx-1 btn-sm"
              onClick={() =>
                props.setEditMode({
                  inEdit: false,
                  process: "",
                })
              }
            >
              Cancel
            </Button>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default EditProcessForm;
