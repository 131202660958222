import React, { useState, useContext } from "react";
import AuthContext from "../context/authContext";
import Dropdown from "react-bootstrap/Dropdown";
import PublicShareModal from "../modals/publicShareModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Lock from "../icons/lock";

function ShareDropdown(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pageCreated, setPageCreated] = useState(false);
  async function generateShareUrl(props) {
    handleShow();
    setPageCreated(false);
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Login to Share
    </Tooltip>
  );

  if (!isAuthenticated) {
    return (
      <React.Fragment>
        <Dropdown alignRight={true} className="d-inline-block mx-1">
          <Dropdown.Toggle
            variant="outline-primary"
            id="public-share-dropdown"
            size="sm"
            className="float-right"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-box-arrow-up-right"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fillRule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Dropdown.ItemText className="text-muted">
                <Lock /> Share to Public
              </Dropdown.ItemText>
            </OverlayTrigger>
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Dropdown alignRight={true} className="d-inline-block mx-1">
          <Dropdown.Toggle
            variant="outline-primary"
            id="public-share-dropdown"
            size="sm"
            className="float-right"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-box-arrow-up-right"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
              />
              <path
                fillRule="evenodd"
                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => generateShareUrl()}>
              Share to Public
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <PublicShareModal
          show={show}
          onHide={handleClose}
          pageCreated={pageCreated}
          setPageCreated={setPageCreated}
          isoActiveProcess={props.isoActiveProcess}
        />
      </React.Fragment>
    );
  }
}

export default ShareDropdown;
