import React from "react";

function FormatLinkData(activeNode, linkData) {
  let activePredecessorLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-activity-before"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeSuccessorLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-activity-after"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeInputLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-object-input"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeOutputlinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-object-output"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeResponsibleLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-responsible"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeSupportLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-support"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeNotifyLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-notify"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  //Object details
  let activeUsedIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-object-input"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeProducedIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-object-output"
    )
    .map((linkSource) => linkSource.link_source_name);
  //Role details
  let activeResponsibleFor = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-responsible"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeSupportIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-support"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeNotifyIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-notify"
    )
    .map((linkSource) => linkSource.link_source_name);
  return {
    activePredecessorLinks,
    activeSuccessorLinks,
    activeInputLinks,
    activeOutputlinks,
    activeResponsibleLinks,
    activeSupportLinks,
    activeNotifyLinks,
    activeUsedIn,
    activeProducedIn,
    activeResponsibleFor,
    activeSupportIn,
    activeNotifyIn,
  };
}

export default FormatLinkData;
