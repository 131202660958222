import React, { useMemo } from "react";
import BpForceDirectedGraph from "../graphs/bpForceDirected";

function PublicSharedGraphContainer(props) {
  const nodes = props.nodes;
  const links = props.links;

  function filteredNodes() {
    if (!props.isoProcess) {
      return nodes;
    } else {
      return nodes.filter(
        (node) => node.process && node.process.includes(props.isoProcess)
      );
    }
  }

  function filteredLinks() {
    if (!props.isoProcess) {
      return links;
    } else {
      const itemids = filteredNodes().map((item) => item.itemid);
      let newLinks = links.filter(
        (i) =>
          itemids.includes(i.source.itemid) || itemids.includes(i.target.itemid)
      );
      return newLinks.filter(
        (x) =>
          filteredNodes().some((y) => y.itemid === x.source.itemid) &&
          filteredNodes().some((y) => y.itemid === x.target.itemid)
      );
    }
  }

  return useMemo(
    () => (
      <BpForceDirectedGraph
        className="h-100 w-100"
        nodeData={filteredNodes()}
        linkData={filteredLinks()}
        setActiveNode={props.setActiveNode}
        activeProcess={props.activeProcess}
        linkSpacing={props.linkSpacing}
      />
    ),
    [props.activeProcess]
  );
}

export default PublicSharedGraphContainer;
