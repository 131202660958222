import React from "react";
import ListGroup from "react-bootstrap/ListGroup";

function LinkListGroup(props) {
  return (
    <ListGroup variant="flush">
      {props.items.map((item, index) => {
        return <ListGroup.Item key={index}>{item}</ListGroup.Item>;
      })}
    </ListGroup>
  );
}

export default LinkListGroup;
