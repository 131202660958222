import React from "react";

function FilteredGraphData(all_nodes, all_links, filter_processes) {
  //Add processes involved in to process prop for objects/roles
  //If node is object or role, look up associated activities from linkdata
  //If associated activity has process, add to new array
  //Remove duplicates from array and convert to string
  function addProcessesToNode() {
    let nodes = all_nodes;
    let links = all_links;
    nodes.forEach((node) => {
      if (node.itemtype !== "activity") {
        let processArr = [];
        let sourceActLinks = links.filter(
          (link) => link.target === node.itemid
        );
        sourceActLinks.forEach((srcAct) => {
          processArr.push(
            nodes.find((i) => i.itemid === srcAct.source).process
          );
        });
        //Remove nulls
        const filteredProcArr = processArr.filter(Boolean);
        //Remove duplicates
        const uniqueArr = [...new Set(filteredProcArr)];
        const nodeIndex = nodes.findIndex((x) => x.itemid === node.itemid);
        nodes[nodeIndex].process = uniqueArr;
      }
    });
    return nodes;
  }

  function filteredNodes() {
    if (filter_processes.length === 0) {
      return addProcessesToNode();
    } else {
      return addProcessesToNode().filter(
        (i) =>
          i.process !== null &&
          i.process !== undefined &&
          (filter_processes.includes(i.process) ||
            filter_processes.some((x) => i.process.includes(x)))
      );
    }
  }

  function filteredLinks() {
    if (filter_processes.length === 0) {
      return all_links;
    } else {
      const itemids = filteredNodes().map((item) => item.itemid);
      let newLinks = all_links.filter(
        (i) => itemids.includes(i.source) || itemids.includes(i.target)
      );
      return newLinks.filter(
        (x) =>
          filteredNodes().some((y) => y.itemid === x.source) &&
          filteredNodes().some((y) => y.itemid === x.target)
      );
    }
  }

  const nodes = filteredNodes();
  const links = filteredLinks();

  return {
    nodes,
    links,
  };
}

export default FilteredGraphData;
