import React, { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { useForm, Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import CenterBoxContainer from "../layout/centerBoxContainer";
import Select from "react-select";

function Contact(props) {
  document.title = "bpviz | contact";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { register, handleSubmit, control } = useForm({});
  const [errMsgs, setErrMsgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const options = [
    { value: "Enhacement", label: "Enhacement" },
    { value: "General Question", label: "General Question" },
    { value: "Issue", label: "Issue" },
  ];

  let history = useHistory();

  async function sendEmail(data) {
    const apiOptions = {
      url: "/email/send-contact-message",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    await axios(apiOptions)
      .then((response) => {
        // console.log("success");
        history.push("/message-sent");
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsgs(error.response.data);
      });
    // console.log(data);
  }

  return (
    <CenterBoxContainer>
      <Form className="p-4" onSubmit={handleSubmit(sendEmail)}>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            name="email"
            type="email"
            placeholder="Enter email"
            ref={register}
          />
        </Form.Group>
        <Form.Label>Contact Category</Form.Label>
        <Controller
          as={<Select options={options} />}
          control={control}
          name="category"
          defaultValue={options[1]}
        />
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>Message</Form.Label>
          <Form.Control
            required
            as="textarea"
            name="message"
            rows={3}
            ref={register}
          />
        </Form.Group>
        <Button variant="primary" type="submit" variant="spotlight">
          Send
        </Button>
        <Form.Text className="text-left text-danger">{errMsgs}</Form.Text>
      </Form>
    </CenterBoxContainer>
  );
}

export default Contact;
