import React, { useState, useContext } from "react";
import ReactGA from "react-ga";
import PmContext from "../context/pmContext";
import MainContainer from "../layout/mainContainer";
import MainGraphContainer from "../layout/mainGraphContainer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import ControlledTabs from "../tabs/tabsContainer";
import AddProcessForm from "../form/processForm";
import ProcessStream from "../graphs/processStream";
import ExportBar from "../graphs/exportBar";
import ItemDetails from "../descriptors/itemDetails";
import "../../custom_styles/sidebar.css";

function Visualizer(props) {
  const [open, setOpen] = useState(true);
  const [linkDataDetail, setLinkDataDetail] = useState([
    { linkSource: "DEFAULT" },
  ]);

  const { processes, setProcesses, nodeData, setNodeData, linkData } =
    useContext(PmContext);

  const [activities, setActivities] = useState([]);
  const [objects, setObjects] = useState([]);
  const [roles, setRoles] = useState([]);
  const [activeNode, setActiveNode] = useState([]);
  //active == in highlighted state
  const [activeProcess, setActiveProcess] = useState([]);
  //isolate == in isolation
  const [isoActiveProcess, setIsoActiveProcess] = useState([]);
  const [linkSpacing, setLinkSpacing] = useState(42.5);
  const mainClass = "bg-white";

  const toggleIcon = (open) => {
    if (open === true) {
      return "<";
    } else {
      return ">";
    }
  };
  document.title = "bpviz | visualizer";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <MainContainer setActiveNode={setActiveNode}>
      <Row className="h-100 no-gutters">
        <Collapse
          id="sidebar"
          dimension="width"
          className={true ? "collapse width yourActiveClass" : "collapse width"}
          in={open}
        >
          <Col lg={3} sm={12} xs={12}>
            <div
              className="bg-light border-right text-dark h-100"
              id="example-collapse-text"
            >
              <h5 className="pt-3">Process Modeling Palette</h5>
              <div className="shadow-sm bg-light text-dark my-2 rounded">
                <AddProcessForm
                  processes={processes}
                  setProcesses={setProcesses}
                />
              </div>
              <div className="shadow-sm bg-light text-dark my-2 rounded">
                <ControlledTabs
                  processes={processes}
                  activities={activities}
                  setActivities={setActivities}
                  objects={objects}
                  setObjects={setObjects}
                  roles={roles}
                  setRoles={setRoles}
                  nodeData={nodeData}
                  setNodeData={setNodeData}
                  // linkData={linkData}
                  // setLinkData={setLinkData}
                  activeNode={activeNode}
                  setActiveNode={setActiveNode}
                  linkDataDetail={linkDataDetail}
                  setLinkDataDetail={setLinkDataDetail}
                />
              </div>
            </div>
          </Col>
        </Collapse>
        <Col className={mainClass}>
          <Button
            className="float-left ml-n1 mt-1 border-right border-bottom border-top
              d-none d-lg-block"
            variant="light"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <div id="collapse-btn-text">{toggleIcon(open)}</div>
          </Button>
          <div
            className="shadow-sm bg-white 
            mx-lg-5
            mx-sm-2 
            mx-2
            my-2"
          >
            <ProcessStream
              processes={processes}
              setProcesses={setProcesses}
              activeProcess={activeProcess}
              setActiveProcess={setActiveProcess}
              isoActiveProcess={isoActiveProcess}
              setIsoActiveProcess={setIsoActiveProcess}
              nodeData={nodeData}
              setNodeData={setNodeData}
              activeNode={activeNode}
              setActiveNode={setActiveNode}
            />
          </div>
          <ExportBar
            isoActiveProcess={isoActiveProcess}
            linkSpacing={linkSpacing}
            setLinkSpacing={setLinkSpacing}
          />
          <MainGraphContainer
            setActiveNode={setActiveNode}
            activeProcess={activeProcess}
            setActiveProcess={setActiveProcess}
            isoActiveProcess={isoActiveProcess}
            linkSpacing={linkSpacing}
          />
          <div
            className="shadow-sm bg-white 
                        mx-lg-5
                        mx-sm-2 
                        mx-2
            my-2"
          >
            <ItemDetails
              nodeData={nodeData}
              linkData={linkData}
              activeNode={activeNode}
              setActiveNode={setActiveNode}
            />
          </div>
        </Col>
      </Row>
    </MainContainer>
  );
}

export default React.memo(Visualizer);
