import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CenterBoxContainer from "../layout/centerBoxContainer";

function Error404() {
  return (
    <CenterBoxContainer>
      <h2 className="my-3 text-secondary">Error 404: Page Not Found</h2>
      <Link to="/visualizer" className="mb-3 mx-5">
        <Button variant="spotlight" size="lg" className="w-100 h-100">
          Go to bpviz
        </Button>
      </Link>
    </CenterBoxContainer>
  );
}

export default Error404;
