import React from "react";
import Card from "react-bootstrap/Card";

function PublicSharedNodeCard(props) {
  function processDisplay(node) {
    if (node.itemtype === "activity") {
      if (node.process === null) {
        return "Belongs to Process: " + "";
      } else {
        return "Belongs to Process: " + node.process;
      }
    } else {
      return "Involved in Process(es): " + node.process;
    }
  }

  return (
    <div>
      <Card className="mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
        <Card.Body>
          <Card.Title>{props.activeNode.itemname}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {props.activeNode.itemtype} <br></br>
            {processDisplay(props.activeNode)}
            {/* Belongs to Process: {props.activeNode.process} */}
          </Card.Subtitle>
        </Card.Body>
      </Card>
      {props.children}
    </div>
  );
}

export default PublicSharedNodeCard;
