import React, { useState, useContext } from "react";
import ReactGA from "react-ga";
import AuthContext from "../context/authContext";
import MainContainer from "../layout/mainContainer";
import Card from "react-bootstrap/Card";
import UserPubSharedNetworkTbl from "../tables/userPubSharedNetworksTbl";

const UserDashboard = (props) => {
  const { name, client, isAuthenticated } = useContext(AuthContext);
  const [activeNode, setActiveNode] = useState([]);
  document.title = "bpviz | user dashboard";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <MainContainer setActiveNode={setActiveNode}>
      <div>
        <Card className="mx-2">
          <Card.Body>
            <Card.Title>user / {name}</Card.Title>
            <Card.Subtitle className="mb-2">
              Public Shared Networks
            </Card.Subtitle>
            <UserPubSharedNetworkTbl username={name} />
          </Card.Body>
        </Card>
        {props.children}
      </div>
    </MainContainer>
  );
};

export default UserDashboard;
