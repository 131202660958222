import React from "react";
import CardGroup from "react-bootstrap/CardGroup";
import FormatLinkData from "../dataUtils/formatLinkData";
import LinkCard from "./linkCard";

function PublicSharedCards(props) {
  const dot = {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    display: "inline-block",
  };
  const linksInfo = () => {
    return props.linkData.map((link) => {
      return {
        link_source_name: link.linkSourceName,
        link_target_name: link.linkTargetName,
        link_type: link.type,
      };
    });
  };
  const actPredLinks = FormatLinkData(props.activeNode, linksInfo())
    .activePredecessorLinks;
  const actSucLinks = FormatLinkData(props.activeNode, linksInfo())
    .activeSuccessorLinks;

  if (props.activeNode.itemtype === "activity") {
    return (
      <>
        <CardGroup>
          <LinkCard
            linkType="Activities Before"
            items={actPredLinks}
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Activities After"
            items={actSucLinks}
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
        </CardGroup>
        <CardGroup>
          <LinkCard
            linkType="Objects Used to Execute Activity"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeInputLinks
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Objects Produced or Modified From Activity"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeOutputlinks
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
        </CardGroup>
        <CardGroup>
          <LinkCard
            linkType="Responsible"
            items={
              FormatLinkData(props.activeNode, linksInfo())
                .activeResponsibleLinks
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Supporting"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeSupportLinks
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Notified"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeNotifyLinks
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
        </CardGroup>
      </>
    );
  }
  if (props.activeNode.itemtype === "object") {
    return (
      <>
        <CardGroup>
          <LinkCard
            linkType="Used in Activities"
            items={FormatLinkData(props.activeNode, linksInfo()).activeUsedIn}
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Produced or Modified in Activities"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeProducedIn
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
        </CardGroup>
      </>
    );
  }
  if (props.activeNode.itemtype === "role") {
    return (
      <>
        <CardGroup>
          <LinkCard
            linkType="Responsible for Activities"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeResponsibleFor
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Supporting in Activities"
            items={
              FormatLinkData(props.activeNode, linksInfo()).activeSupportIn
            }
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
          <LinkCard
            linkType="Notified in Activities"
            items={FormatLinkData(props.activeNode, linksInfo()).activeNotifyIn}
            activeNode={props.activeNode}
            linkData={props.linkData}
            displayEdit={false}
          />
        </CardGroup>
      </>
    );
  } else {
    return null;
  }
}

export default PublicSharedCards;
