import React, { useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import RoleNameInput from "./roleNameInput";
import MultiSelectInput from "./multiSelectInput";
import VerticalCollapseButton from "../buttons/vertCollapseBtn";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";

function createResponsibleLinks(data, all_nodes) {
  return data.activitiesresponsible.map((activity) => ({
    link_source_id: activity.value,
    link_source_name: activity.label,
    link_target_id: "rle_" + all_nodes.length,
    link_target_name: data.itemname,
    link_type: "activity-role-responsible",
  }));
}

function createSupportLinks(data, all_nodes) {
  return data.activitiessupport.map((activity) => ({
    link_source_id: activity.value,
    link_source_name: activity.label,
    link_target_id: "rle_" + all_nodes.length,
    link_target_name: data.itemname,
    link_type: "activity-role-support",
  }));
}

function createNotifyLinks(data, all_nodes) {
  return data.activitiesnotify.map((activity) => ({
    link_source_id: activity.value,
    link_source_name: activity.label,
    link_target_id: "rle_" + all_nodes.length,
    link_target_name: data.itemname,
    link_type: "activity-role-notify",
  }));
}

const defaultValues = {
  itemname: "",
  activitiesresponsible: "",
  activitiesproducedin: "",
  activitiessupport: "",
  activitiesnotify: "",
};

function AddRoleForm(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const [actOpen, setActOpen] = useState(false);
  const { register, handleSubmit, reset, control, watch, errors } = useForm({
    defaultValues,
  });
  const {
    activities,
    setRoles,
    getRoles,
    roles,
    getNodeData,
    setNodeData,
    nodeData,
    getLinkData,
    linkData,
    setLinkData,
  } = useContext(PmContext);
  const [errMsgs, setErrMsgs] = useState([]);
  let onSubmit;
  if (!isAuthenticated) {
    onSubmit = (data) => {
      setRoles((roles) => [...roles, data]);
      setNodeData((nodeData) => [...nodeData, data]);
      let newLinks = [...linkData];
      if (data.activitiesresponsible) {
        createResponsibleLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.activitiessupport) {
        createSupportLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.activitiesnotify) {
        createNotifyLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      setLinkData(newLinks);
      // props.setLinkDataDetail(newLinks);
      reset(defaultValues);
    };
  } else {
    onSubmit = (data) => {
      setErrMsgs();
      const createRoleApi = {
        url: "process-model/create-role",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { itemname: data.itemname },
      };
      async function addRole() {
        await axios(createRoleApi)
          .then(async (response) => {
            const newRoleId = response.data;
            //Link stuffz
            if (data.activitiesresponsible) {
              data.activitiesresponsible.forEach(async (link) => {
                const rleResponsibleApi = {
                  url: "process-model/create-act-rle-res-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    role_id: newRoleId,
                    activity_id: link.value,
                  },
                };
                await axios(rleResponsibleApi)
                  .then((response) => {
                    //   console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.activitiessupport) {
              data.activitiessupport.forEach(async (link) => {
                const rleSupportApi = {
                  url: "process-model/create-act-rle-sup-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    role_id: newRoleId,
                    activity_id: link.value,
                  },
                };
                await axios(rleSupportApi)
                  .then((response) => {
                    //  console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.activitiesnotify) {
              data.activitiesnotify.forEach(async (link) => {
                const rleNotifyApi = {
                  url: "process-model/create-act-rle-nte-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    role_id: newRoleId,
                    activity_id: link.value,
                  },
                };
                await axios(rleNotifyApi)
                  .then((response) => {
                    //   console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            await setRoles((roles) => [...props.roles, data]);
            await getRoles();
            await getNodeData();
            await getLinkData();
            document.getElementById("role-entry-form").reset();
            reset(defaultValues);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(error.response.data[0].msg);
          });
      }
      addRole();
    };
  }
  return (
    <Form id="role-entry-form" onSubmit={handleSubmit(onSubmit)}>
      <RoleNameInput
        roles={props.roles}
        setRoles={props.setRoles}
        watch={watch}
        regRef={register({ required: true, maxLength: 50, minLength: 1 })}
      />
      <div className="text-center mb-2">
        <VerticalCollapseButton
          label="Link to Activities"
          stateSetter={setActOpen}
          state={actOpen}
        />
      </div>
      <Collapse in={actOpen}>
        <div id="task-associations">
          <MultiSelectInput
            items={activities}
            formCont={control}
            relationshipLabel="Responsible for Activities"
            relationshipName="activitiesresponsible"
          />
          <MultiSelectInput
            items={activities}
            formCont={control}
            relationshipLabel="Supporting in Activities"
            relationshipName="activitiessupport"
          />
          <MultiSelectInput
            items={activities}
            formCont={control}
            relationshipLabel="Notified in Activities"
            relationshipName="activitiesnotify"
          />
        </div>
      </Collapse>
      <Form.Text className="text-danger">{errMsgs}</Form.Text>
      <Button variant="primary" type="submit" className="mb-2">
        Submit
      </Button>
    </Form>
  );
}

export default AddRoleForm;
