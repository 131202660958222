import React from "react";

function About() {
  return (
    <div>
      <h1>About</h1>
      <p>This is Nick's cool new app</p>
    </div>
  );
}

export default About;
