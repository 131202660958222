import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { saveSvgAsPng, svgAsDataUri } from "save-svg-as-png";
import { saveAs } from "file-saver";

function ImageExportDropdown(props) {
  function downloadImagePng() {
    saveSvgAsPng(
      document.getElementById("container-svg"),
      "bpviz-process-network.png"
    );
  }

  function downloadImageSvg() {
    svgAsDataUri(document.getElementById("container-svg")).then((uri) => {
      saveAs(uri, "bpviz-process-network.svg");
    });
  }

  return (
    <Dropdown alignRight={true} className="d-inline-block mx-1">
      <Dropdown.Toggle
        variant="outline-primary"
        id="image-download-dropdown"
        size="sm"
        className="float-right"
      >
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-file-image"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 10V2a1 1 0 0 0-1-1H4z"
          />
          <path
            fillRule="evenodd"
            d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => downloadImagePng()}>
          Download as PNG
        </Dropdown.Item>
        <Dropdown.Item onClick={() => downloadImageSvg()}>
          Download as SVG
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ImageExportDropdown;
