import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Container from "react-bootstrap/Container";

function CollectionLoader() {
  return (
    <Container>
      <div className="d-flex justify-content-center my-3">
        <BeatLoader color={"#266e9b"} size={20} />
      </div>
    </Container>
  );
}

export default CollectionLoader;
