import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import PublicShareForm from "../form/publicShareForm";
import Modal from "react-bootstrap/Modal";
import CopyLinkBtn from "../buttons/copyLinkBtn";

function PublicShareModal(props) {
  const [shareLink, setShareLink] = useState();
  const [copyLinkMsg, setCopyLinkMsg] = useState("");
  const inputLink = useRef(null);

  const displayLinkStyle = {
    cursor: "pointer",
  };

  function copyToClipboard() {
    inputLink.current.select();
    document.execCommand("copy");
    setCopyLinkMsg("Link copied to clipboard");
  }

  if (!props.pageCreated) {
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Share to Public</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            This will generate a public URL for others to view your diagram in
            its current state (i.e. data for nodes that aren't visible due to
            isolation will be excluded from the public webpage).
          </p>
          <PublicShareForm
            isoActiveProcess={props.isoActiveProcess}
            setPageCreated={props.setPageCreated}
            handleClose={props.onHide}
            setShareLink={setShareLink}
          />
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Share to Public</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Public URL generated. Your shared business process networks can be
            found in your <Link to="/user-dashboard">Dashboard</Link>.
          </p>
          <p>
            <Link target="_blank" to={shareLink}>
              <input
                className="w-100 bg-light"
                style={displayLinkStyle}
                readOnly
                ref={inputLink}
                value={window.location.origin + "/" + shareLink}
              />
            </Link>
          </p>
          <p className="text-center">
            <CopyLinkBtn
              label="Copy Link to Clipboard"
              onClickFn={copyToClipboard}
            />
          </p>
          <p className="text-center">{copyLinkMsg}</p>
        </Modal.Body>
      </Modal>
    );
  }
}

export default PublicShareModal;
