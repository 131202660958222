import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "./authContext";

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState();

  const setAuth = (boolean) => {
    setIsAuthenticated(boolean);
  };

  const apiVerifyOptions = {
    url: "users/is-verified",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };

  const apiUserOptions = {
    url: "users/user",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };

  function getUser() {
    axios(apiUserOptions)
      .then((response) => {
        const resData = response.data;
        setName(resData.username);
        setClient(resData.client_id);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }

  function isAuth() {
    axios(apiVerifyOptions)
      .then((response) => {
        const resData = response.data;
        if (resData === true) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }

  useEffect(() => {
    isAuth();
    getUser();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, setAuth, name, client }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
