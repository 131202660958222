import React, { useContext } from "react";
import PmContext from "../context/pmContext";
import SpreadsheetExportDropdown from "./spreadsheetExportDropdown";
import ImageExportDropdown from "./imageExportDropdown";
import ShareDropdown from "./shareDropdown";
import LinkSpacingBtnGroup from "./linkSpacingBtnGroup";

function ExportBar(props) {
  const { nodeData } = useContext(PmContext);

  if (nodeData.length > 0) {
    return (
      <React.Fragment>
        <div
          className="
          shadow-sm bg-white
          mx-lg-5
            mx-sm-2 
            mx-2
            mt-2
            text-right"
        >
          <SpreadsheetExportDropdown />
          <ImageExportDropdown />
          <ShareDropdown isoActiveProcess={props.isoActiveProcess} />
          <LinkSpacingBtnGroup
            linkSpacing={props.linkSpacing}
            setLinkSpacing={props.setLinkSpacing}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
}

export default ExportBar;
