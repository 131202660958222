import React, { useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import ActivitytoProcessInput from "./activityProcessInput";
import ActivityNameInput from "./activityNameInput";
import MultiSelectInput from "./multiSelectInput";
import VerticalCollapseButton from "../buttons/vertCollapseBtn";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";

function createPredecessorLinks(data, all_nodes) {
  return data.activitiesbefore.map((activity) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: activity.value,
    link_target_name: activity.label,
    link_type: "activity-activity-before",
  }));
}

function createSucFromPredLinks(data, all_nodes) {
  return data.activitiesbefore.map((activity) => ({
    link_source_id: activity.value,
    link_source_name: activity.label,
    link_target_id: "act_" + all_nodes.length,
    link_target_name: data.itemname,
    link_type: "activity-activity-after",
  }));
}

function createSuccessorLinks(data, all_nodes) {
  return data.activitiesafter.map((activity) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: activity.value,
    link_target_name: activity.label,
    link_type: "activity-activity-after",
  }));
}

function createPredFromSucLinks(data, all_nodes) {
  return data.activitiesafter.map((activity) => ({
    link_source_id: activity.value,
    link_source_name: activity.label,
    link_target_id: "act_" + all_nodes.length,
    link_target_name: data.itemname,
    link_type: "activity-activity-before",
  }));
}

function createInputLinks(data, all_nodes) {
  return data.objectsinput.map((object) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: object.value,
    link_target_name: object.label,
    link_type: "activity-object-input",
  }));
}

function createOutputLinks(data, all_nodes) {
  return data.objectsoutput.map((object) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: object.value,
    link_target_name: object.label,
    link_type: "activity-object-output",
  }));
}

//roles start here
function createResponsibleLinks(data, all_nodes) {
  return data.roleresponsiblefor.map((role) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: role.value,
    link_target_name: role.label,
    link_type: "activity-role-responsible",
  }));
}

function createSupportLinks(data, all_nodes) {
  return data.rolesupportin.map((role) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: role.value,
    link_target_name: role.label,
    link_type: "activity-role-support",
  }));
}

function createNotifyLinks(data, all_nodes) {
  return data.rolenotifiedin.map((role) => ({
    link_source_id: "act_" + all_nodes.length,
    link_source_name: data.itemname,
    link_target_id: role.value,
    link_target_name: role.label,
    link_type: "activity-role-notify",
  }));
}

const defaultValues = {
  itemname: "",
  process: "",
  activitiesbefore: "",
  activitiesafter: "",
  objectsinput: "",
  objectsoutput: "",
  rolenotifiedin: "",
  roleresponsiblefor: "",
  rolesupportin: "",
};

function AddActivityForm(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const [actOpen, setActOpen] = useState(false);
  const [objOpen, setObjOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const { register, handleSubmit, reset, control, errors, watch } = useForm({
    defaultValues,
  });
  const {
    getNodeData,
    setNodeData,
    nodeData,
    linkData,
    setLinkData,
    getLinkData,
    activities,
    setActivities,
    getActivities,
    objects,
    roles,
  } = useContext(PmContext);
  const [errMsgs, setErrMsgs] = useState([]);

  let onSubmit;
  if (!isAuthenticated) {
    onSubmit = (data) => {
      setActivities((activities) => [...activities, data]);
      setNodeData((nodeData) => [...nodeData, data]);
      let newLinks = [...linkData];
      if (data.activitiesbefore) {
        createPredecessorLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
        createSucFromPredLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.activitiesafter) {
        createSuccessorLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
        createPredFromSucLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.objectsinput) {
        createInputLinks(data, nodeData).forEach((item) => newLinks.push(item));
      }
      if (data.objectsoutput) {
        createOutputLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.roleresponsiblefor) {
        createResponsibleLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.rolesupportin) {
        createSupportLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      if (data.rolenotifiedin) {
        createNotifyLinks(data, nodeData).forEach((item) =>
          newLinks.push(item)
        );
      }
      setLinkData(newLinks);
      // props.setLinkDataDetail(newLinks);
      reset(defaultValues);
    };
  } else {
    onSubmit = (data) => {
      setErrMsgs();
      const createActivityApi = {
        url: "process-model/create-activity",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { itemname: data.itemname, process: data.process.value },
      };
      async function addActivity() {
        await axios(createActivityApi)
          .then(async (response) => {
            const newActivityId = response.data;
            //Link stuffz
            if (data.activitiesbefore) {
              data.activitiesbefore.forEach(async (link) => {
                const actBeforeApi = {
                  url: "process-model/create-act-act-bef-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    from_activity_id: newActivityId,
                    to_activity_id: link.value,
                  },
                };
                await axios(actBeforeApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
                const actAfterApi = {
                  url: "process-model/create-act-act-aft-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    from_activity_id: link.value,
                    to_activity_id: newActivityId,
                  },
                };
                await axios(actAfterApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.activitiesafter) {
              data.activitiesafter.forEach(async (link) => {
                const actAfterApi = {
                  url: "process-model/create-act-act-aft-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    from_activity_id: newActivityId,
                    to_activity_id: link.value,
                  },
                };
                await axios(actAfterApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
                const actBeforeApi = {
                  url: "process-model/create-act-act-bef-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    from_activity_id: link.value,
                    to_activity_id: newActivityId,
                  },
                };
                await axios(actBeforeApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.objectsinput) {
              data.objectsinput.forEach(async (link) => {
                const objInpApi = {
                  url: "process-model/create-act-obj-inp-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    activity_id: newActivityId,
                    object_id: link.value,
                  },
                };
                await axios(objInpApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.objectsoutput) {
              data.objectsoutput.forEach(async (link) => {
                const objOutApi = {
                  url: "process-model/create-act-obj-out-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    activity_id: newActivityId,
                    object_id: link.value,
                  },
                };
                await axios(objOutApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.roleresponsiblefor) {
              data.roleresponsiblefor.forEach(async (link) => {
                const rleResApi = {
                  url: "process-model/create-act-rle-res-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    activity_id: newActivityId,
                    role_id: link.value,
                  },
                };
                await axios(rleResApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.rolesupportin) {
              data.rolesupportin.forEach(async (link) => {
                const rleSupApi = {
                  url: "process-model/create-act-rle-sup-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    activity_id: newActivityId,
                    role_id: link.value,
                  },
                };
                await axios(rleSupApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            if (data.rolenotifiedin) {
              data.rolenotifiedin.forEach(async (link) => {
                const rleNteApi = {
                  url: "process-model/create-act-rle-nte-link",
                  method: "POST",
                  headers: {
                    token: localStorage.token,
                  },
                  data: {
                    activity_id: newActivityId,
                    role_id: link.value,
                  },
                };
                await axios(rleNteApi)
                  .then((response) => {
                    // console.log("Link Added");
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setErrMsgs(error.response.data[0].msg);
                  });
              });
            }
            await setActivities((activities) => [...activities, data]);
            await getActivities();
            await getNodeData();
            await getLinkData();
            document.getElementById("activity-entry-form").reset();
            reset(defaultValues);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(error.response.data[0].msg);
          });
      }
      addActivity();
    };
  }

  return (
    <Form id="activity-entry-form" onSubmit={handleSubmit(onSubmit)}>
      <ActivityNameInput
        activities={props.activities}
        setActivities={props.setActivities}
        errMsgs={errMsgs}
        watch={watch}
        regRef={register({ required: true, maxLength: 50, minLength: 1 })}
      />
      <div className="mx-2">
        <ActivitytoProcessInput
          activeNode={props.activeNode}
          formCont={control}
          regRef={register}
          newOrEdit="n"
        />
      </div>
      <div className="text-center mb-2">
        <VerticalCollapseButton
          label="Link to Activities"
          stateSetter={setActOpen}
          state={actOpen}
        />
      </div>
      <Collapse in={actOpen}>
        <div id="task-associations">
          <MultiSelectInput
            items={activities}
            formCont={control}
            relationshipLabel="Activities Before"
            relationshipName="activitiesbefore"
          />
          <MultiSelectInput
            items={activities}
            formCont={control}
            relationshipLabel="Activities After"
            relationshipName="activitiesafter"
          />
        </div>
      </Collapse>
      <div className="text-center mb-2">
        <VerticalCollapseButton
          label="Link to Objects"
          stateSetter={setObjOpen}
          state={objOpen}
        />
      </div>
      <Collapse in={objOpen}>
        <div id="object-associations">
          <MultiSelectInput
            items={objects}
            formCont={control}
            relationshipLabel="Objects Used to Execute Activty"
            relationshipName="objectsinput"
          />
          <MultiSelectInput
            items={objects}
            formCont={control}
            relationshipLabel="Objects Produced or Modified from Activty"
            relationshipName="objectsoutput"
          />
        </div>
      </Collapse>
      <div className="text-center mb-2">
        <VerticalCollapseButton
          label="Link to Roles"
          stateSetter={setRoleOpen}
          state={roleOpen}
        />
      </div>
      <Collapse in={roleOpen}>
        <div id="role-associations">
          <MultiSelectInput
            items={roles}
            formCont={control}
            relationshipLabel="Responsible for Activities"
            relationshipName="roleresponsiblefor"
          />
          <MultiSelectInput
            items={roles}
            formCont={control}
            relationshipLabel="Supporting in Activities"
            relationshipName="rolesupportin"
          />
          <MultiSelectInput
            items={roles}
            formCont={control}
            relationshipLabel="Notified of Activities"
            relationshipName="rolenotifiedin"
          />
        </div>
      </Collapse>
      <Button variant="primary" type="submit" className="mb-2">
        Submit
      </Button>
    </Form>
  );
}

export default AddActivityForm;
