import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

function LoginMenu(props) {
  const { setAuth, isAuthenticated, name } = useContext(AuthContext);
  const { resetAllState } = useContext(PmContext);

  function logout() {
    localStorage.removeItem("token");
    resetAllState();
    if (!props.setActiveNode) {
    } else {
      props.setActiveNode([]);
    }
    setAuth(false);
  }

  const usernameBtn = {
    minWidth: "5rem",
  };

  if (!isAuthenticated) {
    return (
      <Nav>
        <Navbar.Text>
          <Link to="/contact">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-person-lines-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </Link>{" "}
          | <Link to="/login">Login</Link> |{" "}
          <Button
            href="/register"
            variant="spotlight"
            className="py-0 px-1"
            style={usernameBtn}
          >
            {"   Register   "}
          </Button>
        </Navbar.Text>
      </Nav>
    );
  } else {
    return (
      <Nav>
        <Navbar.Text>
          <Link to="/contact">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-person-lines-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </Link>{" "}
          |
          <Button className="py-0 px-1" onClick={() => logout()}>
            Logout
          </Button>{" "}
          |{" "}
          <Button variant="spotlight" className="py-0 px-1" style={usernameBtn}>
            {"   "}
            <Link to="/user-dashboard" style={{ textDecoration: "none" }}>
              {name}
            </Link>
            {"   "}
          </Button>
        </Navbar.Text>
      </Nav>
    );
  }
}

export default LoginMenu;
