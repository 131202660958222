import React, { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import PmContext from "../context/pmContext";
import { saveAs } from "file-saver";
const { Parser } = require("json2csv");

function SpreadsheetExportDropdown(props) {
  const { nodeData, linkData } = useContext(PmContext);

  function idPreface(item, idx) {
    if (item.itemtype === "activity") {
      return "act_" + idx;
    }
    if (item.itemtype === "object") {
      return "obj_" + idx;
    }
    if (item.itemtype === "role") {
      return "rle_" + idx;
    }
  }

  let nodes = () => {
    if (nodeData.some((node) => node.hasOwnProperty("item_id"))) {
      return nodeData;
    } else {
      return nodeData.map((node, index) => {
        return {
          item_id: idPreface(node, index),
          item_name: node.itemname,
          item_type: node.itemtype,
          process: node.process ? node.process.label : "",
        };
      });
    }
  };

  function getActivityPreds(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-activity-before") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivitySucs(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-activity-after") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivityInps(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-object-input") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivityOuts(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-object-output") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivityRes(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-responsible") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivitySup(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-support") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getActivityNte(activity) {
    let links = linkData.filter((i) => i.link_source_id === activity);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-notify") {
        targetArr.push(link.link_target_name);
      }
    });
    return targetArr.join("|");
  }

  function getObjectInp(object) {
    let links = linkData.filter((i) => i.link_target_id === object);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-object-input") {
        targetArr.push(link.link_source_name);
      }
    });
    return targetArr.join("|");
  }

  function getObjectOut(object) {
    let links = linkData.filter((i) => i.link_target_id === object);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-object-output") {
        targetArr.push(link.link_source_name);
      }
    });
    return targetArr.join("|");
  }

  function getRolesRes(role) {
    let links = linkData.filter((i) => i.link_target_id === role);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-responsible") {
        targetArr.push(link.link_source_name);
      }
    });
    return targetArr.join("|");
  }

  function getRolesSup(role) {
    let links = linkData.filter((i) => i.link_target_id === role);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-support") {
        targetArr.push(link.link_source_name);
      }
    });
    return targetArr.join("|");
  }

  function getRolesNte(role) {
    let links = linkData.filter((i) => i.link_target_id === role);
    let targetArr = [];
    links.forEach((link) => {
      if (link.link_type === "activity-role-notify") {
        targetArr.push(link.link_source_name);
      }
    });
    return targetArr.join("|");
  }

  function formatDataForCsv() {
    let actNodeData = nodes().filter((i) => i.item_type === "activity");
    return actNodeData.map((node, index) => {
      return {
        activity: node.item_name,
        process: node.process,
        activities_before: getActivityPreds(node.item_id),
        activities_after: getActivitySucs(node.item_id),
        objects_used: getActivityInps(node.item_id),
        objects_produced_or_modified: getActivityOuts(node.item_id),
        responsible_roles: getActivityRes(node.item_id),
        supporting_roles: getActivitySup(node.item_id),
        notified_roles: getActivityNte(node.item_id),
      };
    });
  }

  function formatDataForObjCsv() {
    let objNodeData = nodes().filter((i) => i.item_type === "object");
    return objNodeData.map((node, index) => {
      return {
        object: node.item_name,
        used_in_activities: getObjectInp(node.item_id),
        produced_or_modified_in_activities: getObjectOut(node.item_id),
      };
    });
  }

  function formatDataForRleCsv() {
    let rleNodeData = nodes().filter((i) => i.item_type === "role");
    return rleNodeData.map((node, index) => {
      return {
        role: node.item_name,
        responsible_for_activities: getRolesRes(node.item_id),
        supporting_in_activities: getRolesSup(node.item_id),
        notified_in_activities: getRolesNte(node.item_id),
      };
    });
  }

  function exportAsActCsv() {
    const fields = [
      "activity",
      "process",
      "activities_before",
      "activities_after",
      "objects_used",
      "objects_produced_or_modified",
      "responsible_roles",
      "supporting_roles",
      "notified_roles",
    ];
    const opts = { fields };
    const json2csvParer = new Parser(opts);
    const exportData = formatDataForCsv();
    const csv = json2csvParer.parse(exportData);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, "bpviz-activities.csv");
  }

  function exportObjAsCsv() {
    const fields = [
      "object",
      "used_in_activities",
      "produced_or_modified_in_activities",
    ];
    const opts = { fields };
    const json2csvParer = new Parser(opts);
    const exportData = formatDataForObjCsv();
    const csv = json2csvParer.parse(exportData);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, "bpviz-objects.csv");
  }

  function exportRleAsCsv() {
    const fields = [
      "role",
      "responsible_for_activities",
      "supporting_in_activities",
      "notified_in_activities",
    ];
    const opts = { fields };
    const json2csvParer = new Parser(opts);
    const exportData = formatDataForRleCsv();
    const csv = json2csvParer.parse(exportData);
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, "bpviz-roles.csv");
  }
  return (
    <Dropdown alignRight={true} className="d-inline-block mx-1">
      <Dropdown.Toggle
        variant="outline-primary"
        id="csv-download-dropdown"
        size="sm"
        className="float-right"
      >
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-file-earmark-spreadsheet"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5 10H3V9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2zm1 0v2h3v-2H6z"
          />
          <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
          <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => exportAsActCsv()}>
          Export Activity Data as CSV
        </Dropdown.Item>
        <Dropdown.Item onClick={() => exportObjAsCsv()}>
          Export Object Data as CSV
        </Dropdown.Item>
        <Dropdown.Item onClick={() => exportRleAsCsv()}>
          Export Role Data as CSV
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SpreadsheetExportDropdown;
