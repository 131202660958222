import React, { useContext } from "react";
import axios from "axios";
import PmContext from "../context/pmContext";
import Dropdown from "react-bootstrap/Dropdown";

function DeleteProcessAction(props) {
  const { loading, processes, setProcesses, getProcesses } = useContext(
    PmContext
  );
  async function deleteProcess(processId, process) {
    const nodeData = props.nodeData;
    //Remove from process prop in main node listing
    let newNodeList = [...nodeData];
    newNodeList.forEach((node, index) => {
      if (node.process === props.processName) {
        newNodeList[index].process = "";
      }
    });
    props.setNodeData(newNodeList);

    //Remove from active processes if needed
    const newActiveProcess = props.activeProcess.filter(
      (currentProc) => currentProc !== process
    );
    props.setActiveProcess(newActiveProcess);

    //Remove from active node if needed
    let newActiveNode = props.activeNode;
    if (newActiveNode.process === process) {
      newActiveNode.process = "";
    }
    props.setActiveNode(newActiveNode);

    const newProcessList = processes.filter(
      (proc) => proc.processname !== process
    );
    await setProcesses(newProcessList);

    //API call to delete process from db
    const apiOptions = {
      url: "process-model/delete-process",
      method: "POST",
      headers: {
        token: localStorage.token,
      },
      data: { process_id: processId },
    };
    await axios(apiOptions)
      .then((response) => {
        const resData = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
    await getProcesses();
  }

  return (
    <Dropdown.Item
      onClick={() => {
        deleteProcess(props.processId, props.processName);
      }}
    >
      Delete
    </Dropdown.Item>
  );
}

export default DeleteProcessAction;
