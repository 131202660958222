import React, { useState, useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import MultiSelectInput from "./multiSelectInput";

// Activity Details Options
function createPredLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-activity-before",
  };
}

function createSucFromPredLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-activity-after",
  };
}

function createSucLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-activity-after",
  };
}

function createPredFromSucLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-activity-before",
  };
}

function createInpLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-object-input",
  };
}

function createOutLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-object-output",
  };
}

function createResLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-role-responsible",
  };
}

function createSupLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-role-support",
  };
}

function createNteLink(data, link) {
  return {
    link_source_id: data.itemid,
    link_source_name: data.itemname,
    link_target_id: link.value,
    link_target_name: link.label,
    link_type: "activity-role-notify",
  };
}

//Object Details Options
function createObjInpLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-object-input",
  };
}

function createObjOutLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-object-output",
  };
}

//Role Details Options
function createRleResLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-role-responsible",
  };
}

function createRleSupLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-role-support",
  };
}

function createRleNteLink(data, link) {
  return {
    link_source_id: link.value,
    link_source_name: link.label,
    link_target_id: data.itemid,
    link_target_name: data.itemname,
    link_type: "activity-role-notify",
  };
}

function UpdateLinkForm(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { register, handleSubmit, control } = useForm();
  const {
    linkData,
    getLinkData,
    setLinkData,
    activities,
    objects,
    roles,
  } = useContext(PmContext);
  const activeNode = props.activeNode;
  const [errMsgs, setErrMsgs] = useState([]);

  let filteredLinkData = linkData.filter(function (item) {
    if (props.linkType === "Activities Before") {
      if (
        (item.linkSource === activeNode.itemname &&
          item.type === "activity-activity-before") ||
        (item.linkTarget === activeNode.itemname &&
          item.type === "activity-activity-after")
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Activities After") {
      if (
        (item.linkSource === activeNode.itemname &&
          item.type === "activity-activity-after") ||
        (item.linkTarget === activeNode.itemname &&
          item.type === "activity-activity-before")
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Objects Used to Execute Activity") {
      if (
        item.linkSource === activeNode.itemname &&
        item.type === "activity-object-input"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Objects Produced or Modified From Activity") {
      if (
        item.linkSource === activeNode.itemname &&
        item.type === "activity-object-output"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Responsible") {
      if (
        item.linkSource === activeNode.itemname &&
        item.type === "activity-role-responsible"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Supporting") {
      if (
        item.linkSource === activeNode.itemname &&
        item.type === "activity-role-support"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Notified") {
      if (
        item.linkSource === activeNode.itemname &&
        item.type === "activity-role-notify"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Used in Activities") {
      if (
        item.linkTarget === activeNode.itemname &&
        item.type === "activity-object-input"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Produced or Modified in Activities") {
      if (
        item.linkTarget === activeNode.itemname &&
        item.type === "activity-object-output"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Responsible for Activities") {
      if (
        item.linkTarget === activeNode.itemname &&
        item.type === "activity-role-responsible"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Supporting in Activities") {
      if (
        item.linkTarget === activeNode.itemname &&
        item.type === "activity-role-support"
      ) {
        return false;
      }
      return true;
    }
    if (props.linkType === "Notified in Activities") {
      if (
        item.linkTarget === activeNode.itemname &&
        item.type === "activity-role-notify"
      ) {
        return false;
      }
      return true;
    }
  });
  async function onSubmit(data) {
    //Filter linkData array to return links not involving active node
    //Push new array to the result of that based on selections in multi select input
    let updatedLinks = filteredLinkData;
    if (!isAuthenticated) {
      if (data.activitiesbefore || data.activitiesbefore === null) {
        if (data.activitiesbefore === null) {
          data.activitiesbefore = [];
        }
        data.activitiesbefore.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createPredLink(data, link));
            updatedLinks.push(createSucFromPredLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaPred = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-activity-before",
          };
          const filterCriteriaSuc = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-activity-after",
          };
          if (!data.activitiesbefore.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaPred) {
                if (
                  item[key] === undefined ||
                  item[key] != filterCriteriaPred[key]
                )
                  return true;
              }
              return false;
            });
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaSuc) {
                if (
                  item[key] === undefined ||
                  item[key] != filterCriteriaSuc[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiesafter || data.activitiesafter === null) {
        if (data.activitiesafter === null) {
          data.activitiesafter = [];
        }
        data.activitiesafter.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createSucLink(data, link));
            updatedLinks.push(createPredFromSucLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaSuc = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-activity-after",
          };
          const filterCriteriaPred = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-activity-before",
          };
          if (!data.activitiesafter.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaSuc) {
                if (
                  item[key] === undefined ||
                  item[key] != filterCriteriaSuc[key]
                )
                  return true;
              }
              return false;
            });
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaPred) {
                if (
                  item[key] === undefined ||
                  item[key] != filterCriteriaPred[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.objectsinput || data.objectsinput === null) {
        if (data.objectsinput === null) {
          data.objectsinput = [];
        }
        data.objectsinput.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createInpLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaInp = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-object-input",
          };
          if (!data.objectsinput.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaInp) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteriaInp[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.objectsoutput || data.objectsoutput === null) {
        if (data.objectsoutput === null) {
          data.objectsoutput = [];
        }
        data.objectsoutput.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createOutLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaOut = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-object-output",
          };
          if (!data.objectsoutput.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaOut) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteriaOut[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.roleresponsiblefor || data.roleresponsiblefor === null) {
        if (data.roleresponsiblefor === null) {
          data.roleresponsiblefor = [];
        }
        data.roleresponsiblefor.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createResLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaOut = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-role-responsible",
          };
          if (!data.roleresponsiblefor.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaOut) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteriaOut[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.rolesupportin || data.rolesupportin === null) {
        if (data.rolesupportin === null) {
          data.rolesupportin = [];
        }
        data.rolesupportin.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createSupLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaOut = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-role-support",
          };
          if (!data.rolesupportin.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaOut) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteriaOut[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.rolenotifiedin || data.rolenotifiedin === null) {
        if (data.rolenotifiedin === null) {
          data.rolenotifiedin = [];
        }
        data.rolenotifiedin.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createNteLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteriaOut = {
            link_source_id: data.itemid,
            link_target_id: oldLink.value,
            link_type: "activity-role-notify",
          };
          if (!data.rolenotifiedin.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteriaOut) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteriaOut[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiesusedin || data.activitiesusedin === null) {
        if (data.activitiesusedin === null) {
          data.activitiesusedin = [];
        }
        data.activitiesusedin.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createObjInpLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteria = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-object-input",
          };
          if (!data.activitiesusedin.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteria) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteria[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiesproducedin || data.activitiesproducedin === null) {
        if (data.activitiesproducedin === null) {
          data.activitiesproducedin = [];
        }
        data.activitiesproducedin.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createObjOutLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteria = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-object-output",
          };
          if (!data.activitiesproducedin.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteria) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteria[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiesresponsible || data.activitiesresponsible === null) {
        if (data.activitiesresponsible === null) {
          data.activitiesresponsible = [];
        }
        data.activitiesresponsible.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createRleResLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteria = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-role-responsible",
          };
          if (!data.activitiesresponsible.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteria) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteria[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiessupport || data.activitiessupport === null) {
        if (data.activitiessupport === null) {
          data.activitiessupport = [];
        }
        data.activitiessupport.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createRleSupLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteria = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-role-support",
          };
          if (!data.activitiessupport.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteria) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteria[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      if (data.activitiesnotify || data.activitiesnotify === null) {
        if (data.activitiesnotify === null) {
          data.activitiesnotify = [];
        }
        data.activitiesnotify.forEach((link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            updatedLinks.push(createRleNteLink(data, link));
          }
        });
        //If link only in old array, remove
        data.initialvalues.forEach((oldLink) => {
          const filterCriteria = {
            link_source_id: oldLink.value,
            link_target_id: data.itemid,
            link_type: "activity-role-notify",
          };
          if (!data.activitiesnotify.includes(oldLink)) {
            //Filter new link array to remove items
            updatedLinks = updatedLinks.filter(function (item) {
              for (var key in filterCriteria) {
                if (
                  item[key] === undefined ||
                  item[key] !== filterCriteria[key]
                )
                  return true;
              }
              return false;
            });
          }
        });
      }
      setLinkData(updatedLinks);
      props.setLinkEditMode(false);
    } else {
      const itemid = props.activeNode.itemid.split("_")[1];
      if (data.activitiesbefore || data.activitiesbefore === null) {
        if (data.activitiesbefore === null) {
          data.activitiesbefore = [];
        }
        await data.activitiesbefore.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actBeforeApi = {
              url: "process-model/create-act-act-bef-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: itemid,
                to_activity_id: link.value,
              },
            };
            await axios(actBeforeApi)
              .then(async (response) => {
                //   console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            const actAfterApi = {
              url: "process-model/create-act-act-aft-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: link.value,
                to_activity_id: itemid,
              },
            };
            await axios(actAfterApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesbefore.includes(oldLink)) {
            const delActBeforeApi = {
              url: "process-model/delete-act-act-bef-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: itemid,
                to_activity_id: oldLink.value,
              },
            };
            await axios(delActBeforeApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            const delActAfterApi = {
              url: "process-model/delete-act-act-aft-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: oldLink.value,
                to_activity_id: itemid,
              },
            };
            await axios(delActAfterApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiesafter || data.activitiesafter === null) {
        if (data.activitiesafter === null) {
          data.activitiesafter = [];
        }
        await data.activitiesafter.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actAfterApi = {
              url: "process-model/create-act-act-aft-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: itemid,
                to_activity_id: link.value,
              },
            };
            await axios(actAfterApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            const actBeforeApi = {
              url: "process-model/create-act-act-bef-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: link.value,
                to_activity_id: itemid,
              },
            };
            await axios(actBeforeApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesafter.includes(oldLink)) {
            const delActAftereApi = {
              url: "process-model/delete-act-act-aft-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: itemid,
                to_activity_id: oldLink.value,
              },
            };
            await axios(delActAftereApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            const delActBeforeApi = {
              url: "process-model/delete-act-act-bef-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                from_activity_id: oldLink.value,
                to_activity_id: itemid,
              },
            };
            await axios(delActBeforeApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }

      if (data.objectsinput || data.objectsinput === null) {
        if (data.objectsinput === null) {
          data.objectsinput = [];
        }
        await data.objectsinput.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actObjInputApi = {
              url: "process-model/create-act-obj-inp-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                object_id: link.value,
              },
            };
            await axios(actObjInputApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.objectsinput.includes(oldLink)) {
            const delObjInputApi = {
              url: "process-model/delete-act-obj-inp-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                object_id: oldLink.value,
              },
            };
            await axios(delObjInputApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.objectsoutput || data.objectsoutput === null) {
        if (data.objectsoutput === null) {
          data.objectsoutput = [];
        }
        await data.objectsoutput.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actObjOutputApi = {
              url: "process-model/create-act-obj-out-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                object_id: link.value,
              },
            };
            await axios(actObjOutputApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.objectsoutput.includes(oldLink)) {
            const delObjOutputApi = {
              url: "process-model/delete-act-obj-out-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                object_id: oldLink.value,
              },
            };
            await axios(delObjOutputApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.roleresponsiblefor || data.roleresponsiblefor === null) {
        if (data.roleresponsiblefor === null) {
          data.roleresponsiblefor = [];
        }
        await data.roleresponsiblefor.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleResApi = {
              url: "process-model/create-act-rle-res-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: link.value,
              },
            };
            await axios(actRleResApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.roleresponsiblefor.includes(oldLink)) {
            const delRleResApi = {
              url: "process-model/delete-act-rle-res-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: oldLink.value,
              },
            };
            await axios(delRleResApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.rolesupportin || data.rolesupportin === null) {
        if (data.rolesupportin === null) {
          data.rolesupportin = [];
        }
        await data.rolesupportin.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleSupApi = {
              url: "process-model/create-act-rle-sup-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: link.value,
              },
            };
            await axios(actRleSupApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.rolesupportin.includes(oldLink)) {
            const delRleSupApi = {
              url: "process-model/delete-act-rle-sup-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: oldLink.value,
              },
            };
            await axios(delRleSupApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.rolenotifiedin || data.rolenotifiedin === null) {
        if (data.rolenotifiedin === null) {
          data.rolenotifiedin = [];
        }
        await data.rolenotifiedin.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleNteApi = {
              url: "process-model/create-act-rle-nte-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: link.value,
              },
            };
            await axios(actRleNteApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.rolenotifiedin.includes(oldLink)) {
            const delRleNteApi = {
              url: "process-model/delete-act-rle-nte-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                activity_id: itemid,
                role_id: oldLink.value,
              },
            };
            await axios(delRleNteApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiesusedin || data.activitiesusedin === null) {
        if (data.activitiesusedin === null) {
          data.activitiesusedin = [];
        }
        await data.activitiesusedin.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actObjInpApi = {
              url: "process-model/create-act-obj-inp-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                object_id: itemid,
                activity_id: link.value,
              },
            };
            await axios(actObjInpApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesusedin.includes(oldLink)) {
            const delObjInpApi = {
              url: "process-model/delete-act-obj-inp-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                object_id: itemid,
                activity_id: oldLink.value,
              },
            };
            await axios(delObjInpApi)
              .then(async (response) => {
                //   console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiesproducedin || data.activitiesproducedin === null) {
        if (data.activitiesproducedin === null) {
          data.activitiesproducedin = [];
        }
        await data.activitiesproducedin.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actObjOutApi = {
              url: "process-model/create-act-obj-out-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                object_id: itemid,
                activity_id: link.value,
              },
            };
            await axios(actObjOutApi)
              .then(async (response) => {
                //   console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesproducedin.includes(oldLink)) {
            const delObjOutApi = {
              url: "process-model/delete-act-obj-out-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                object_id: itemid,
                activity_id: oldLink.value,
              },
            };
            await axios(delObjOutApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiesresponsible || data.activitiesresponsible === null) {
        if (data.activitiesresponsible === null) {
          data.activitiesresponsible = [];
        }
        await data.activitiesresponsible.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleResApi = {
              url: "process-model/create-act-rle-res-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: link.value,
              },
            };
            await axios(actRleResApi)
              .then(async (response) => {
                //    console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesresponsible.includes(oldLink)) {
            const delRleResApi = {
              url: "process-model/delete-act-rle-res-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: oldLink.value,
              },
            };
            await axios(delRleResApi)
              .then(async (response) => {
                //   console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiessupport || data.activitiessupport === null) {
        if (data.activitiessupport === null) {
          data.activitiessupport = [];
        }
        await data.activitiessupport.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleSupApi = {
              url: "process-model/create-act-rle-sup-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: link.value,
              },
            };
            await axios(actRleSupApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiessupport.includes(oldLink)) {
            const delRleSupApi = {
              url: "process-model/delete-act-rle-sup-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: oldLink.value,
              },
            };
            await axios(delRleSupApi)
              .then(async (response) => {
                //  console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
      if (data.activitiesnotify || data.activitiesnotify === null) {
        if (data.activitiesnotify === null) {
          data.activitiesnotify = [];
        }
        await data.activitiesnotify.forEach(async (link) => {
          //If link in old array, ignore
          if (data.initialvalues.includes(link)) {
          }
          //If link not in old array, add
          else if (!data.initialvalues.includes(link)) {
            const actRleNteApi = {
              url: "process-model/create-act-rle-nte-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: link.value,
              },
            };
            await axios(actRleNteApi)
              .then(async (response) => {
                //  console.log("Link Added");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        //If link only in old array, remove
        await data.initialvalues.forEach(async (oldLink) => {
          if (!data.activitiesnotify.includes(oldLink)) {
            const delRleNteApi = {
              url: "process-model/delete-act-rle-nte-link",
              method: "POST",
              headers: {
                token: localStorage.token,
              },
              data: {
                role_id: itemid,
                activity_id: oldLink.value,
              },
            };
            await axios(delRleNteApi)
              .then(async (response) => {
                //   console.log("Link Removed");
              })
              .catch((error) => {
                console.log(error.response);
                setErrMsgs(error.response.data[0].msg);
              });
            await getLinkData();
          }
        });
        props.setLinkEditMode(false);
      }
    }
  }

  function getDefaultVals(linkType) {
    //If active node is activity, show targets
    //If active node is other, show sources
    if (!isAuthenticated) {
      if (props.activeNode.itemtype === "activity") {
        return linkData
          .filter(
            (link) =>
              link.link_type === linkType &&
              link.link_source_name === props.activeNode.itemname
          )
          .map((item, index) => ({
            key: index,
            value: item.link_target_id,
            label: item.link_target_name,
          }));
      } else {
        return linkData
          .filter(
            (link) =>
              link.link_type === linkType &&
              link.link_target_name === props.activeNode.itemname
          )
          .map((item, index) => ({
            key: index,
            value: item.link_source_id,
            label: item.link_source_name,
          }));
      }
    } else {
      if (props.activeNode.itemtype === "activity") {
        return linkData
          .filter(
            (link) =>
              link.link_type === linkType &&
              link.link_source_name === props.activeNode.itemname
          )
          .map((item, index) => ({
            key: index,
            value: item.link_target_id.split("_")[1],
            label: item.link_target_name,
          }));
      } else {
        return linkData
          .filter(
            (link) =>
              link.link_type === linkType &&
              link.link_target_name === props.activeNode.itemname
          )
          .map((item, index) => ({
            key: index,
            value: item.link_source_id.split("_")[1],
            label: item.link_source_name,
          }));
      }
    }
  }

  if (props.linkType === "Activities Before") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities.filter((item) => item.name !== activeNode.itemname)}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-activity-before")}
          formCont={control}
          relationshipLabel="Activities Before"
          relationshipName="activitiesbefore"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Activities After") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities.filter((item) => item.name !== activeNode.itemname)}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-activity-after")}
          formCont={control}
          relationshipLabel="Activities After"
          relationshipName="activitiesafter"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Objects Used to Execute Activity") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={objects}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-object-input")}
          formCont={control}
          relationshipLabel="Objects Used to Execute Activity"
          relationshipName="objectsinput"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Objects Produced or Modified From Activity") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={objects}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-object-output")}
          formCont={control}
          relationshipLabel="Objects Produced or Modified From Activity"
          relationshipName="objectsoutput"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Responsible") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={roles}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-responsible")}
          formCont={control}
          relationshipLabel="Responsible"
          relationshipName="roleresponsiblefor"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Supporting") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={roles}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-support")}
          formCont={control}
          relationshipLabel="Support"
          relationshipName="rolesupportin"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Notified") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={roles}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-notify")}
          formCont={control}
          relationshipLabel="Notified"
          relationshipName="rolenotifiedin"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Used in Activities") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-object-input")}
          formCont={control}
          relationshipLabel="Used in Activities"
          relationshipName="activitiesusedin"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Produced or Modified in Activities") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-object-output")}
          formCont={control}
          relationshipLabel="Produced or Modified in Activities"
          relationshipName="activitiesproducedin"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Responsible for Activities") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-responsible")}
          formCont={control}
          relationshipLabel="Responsible for Activities"
          relationshipName="activitiesresponsible"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Supporting in Activities") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-support")}
          formCont={control}
          relationshipLabel="Supporting in Activities"
          relationshipName="activitiessupport"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
  if (props.linkType === "Notified in Activities") {
    return (
      <Form id="update-link-form" onSubmit={handleSubmit(onSubmit)}>
        <MultiSelectInput
          items={activities}
          isUpdate="y"
          activeNode={props.activeNode}
          defaultValues={getDefaultVals("activity-role-notify")}
          formCont={control}
          relationshipLabel="Notified in Activities"
          relationshipName="activitiesnotify"
        />
        <Form.Control
          className="d-none"
          name="itemname"
          type="text"
          defaultValue={props.activeNode.itemname}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="itemid"
          type="text"
          defaultValue={props.activeNode.itemid}
          ref={register}
        />
        <Button variant="outline-primary" type="submit" className="mx-1 btn-sm">
          Save
        </Button>
        <Button
          variant="outline-danger"
          className="mx-1 btn-sm"
          onClick={() => props.setLinkEditMode(false)}
        >
          Cancel
        </Button>
      </Form>
    );
  }
}

export default UpdateLinkForm;
