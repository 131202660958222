import React from "react";
import Button from "react-bootstrap/Button";
import CopyLink from "../icons/copyLink";

function CopyLinkBtn(props) {
  return (
    <Button
      className="btn btn-primary btn-sm"
      onClick={() => props.onClickFn()}
    >
      <CopyLink /> {props.label}
    </Button>
  );
}

export default CopyLinkBtn;
