import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

function AddProcessForm(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { processes, setProcesses, getProcesses } = useContext(PmContext);
  const [errMsgs, setErrMsgs] = useState([]);
  const { register, handleSubmit, watch, errors } = useForm();
  const watchName = watch("processname", "");
  const errMsgTextStyle = () => {
    if (watchName.length > 50) {
      return "text-danger";
    }
  };

  let onSubmit;
  if (!isAuthenticated) {
    onSubmit = (data) => {
      //Map process to processes giving process_id and process_name
      const newProcess = {
        process_id: processes.length,
        process_name: data.processname,
        processname: data.processname,
      };
      setProcesses((processes) => [...processes, newProcess]);
      document.getElementById("process-entry-form").reset();
    };
  } else {
    onSubmit = (data) => {
      setErrMsgs();
      const apiOptions = {
        url: "process-model/create-process",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: data,
      };
      async function addProcess() {
        await axios(apiOptions)
          .then((response) => {
            const resData = response.data;
            setProcesses((processes) => [...processes, data]);
            getProcesses();
            document.getElementById("process-entry-form").reset();
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(error.response.data[0].msg);
          });
      }
      addProcess();
    };
  }

  return (
    <Form id="process-entry-form" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="" className="mx-2">
        <Form.Label>Add Process</Form.Label>
        <Form.Control
          required
          name="processname"
          type="text"
          placeholder="Enter Process Name"
          ref={register({
            minLength: 1,
            maxLength: 50,
          })}
        />
        <Form.Text className={errMsgTextStyle()}>
          {watchName.length + "/50"}
        </Form.Text>
        {/* <Form.Text className="text-danger">
          {errors.processname && "Exceeded maximum process name length"}
        </Form.Text> */}
        <Form.Text className="text-danger">{errMsgs}</Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit" className="mb-2">
        Submit
      </Button>
    </Form>
  );
}

export default AddProcessForm;
