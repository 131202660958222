import React, { useContext } from "react";
import CardGroup from "react-bootstrap/CardGroup";
import NodeCard from "./nodeCard";
import LinkCard from "./linkCard";
import PmContext from "../context/pmContext";

function ItemDetails(props) {
  const { nodeData, setNodeData, linkData } = useContext(PmContext);
  const activeNode = props.activeNode;
  let activePredecessorLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-activity-before"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeSuccessorLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-activity-after"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeInputLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-object-input"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeOutputlinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-object-output"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeResponsibleLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-responsible"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeSupportLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-support"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  let activeNotifyLinks = linkData
    .filter(
      (option) =>
        option.link_source_name === activeNode.itemname &&
        option.link_type === "activity-role-notify"
    )
    .map((linkTarget) => linkTarget.link_target_name);
  //Object details
  let activeUsedIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-object-input"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeProducedIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-object-output"
    )
    .map((linkSource) => linkSource.link_source_name);
  //Role details
  let activeResponsibleFor = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-responsible"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeSupportIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-support"
    )
    .map((linkSource) => linkSource.link_source_name);
  let activeNotifyIn = linkData
    .filter(
      (option) =>
        option.link_target_name === activeNode.itemname &&
        option.link_type === "activity-role-notify"
    )
    .map((linkSource) => linkSource.link_source_name);

  if (props.activeNode.length != 0) {
    if (props.activeNode.itemtype === "activity") {
      return (
        <NodeCard
          activeNode={props.activeNode}
          setActiveNode={props.setActiveNode}
        >
          <CardGroup>
            <LinkCard
              linkType="Activities Before"
              items={activePredecessorLinks}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Activities After"
              items={activeSuccessorLinks}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
          </CardGroup>
          <CardGroup>
            <LinkCard
              linkType="Objects Used to Execute Activity"
              items={activeInputLinks}
              allItemsOfType={props.objects}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Objects Produced or Modified From Activity"
              items={activeOutputlinks}
              allItemsOfType={props.objects}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
          </CardGroup>
          <CardGroup>
            <LinkCard
              linkType="Responsible"
              items={activeResponsibleLinks}
              allItemsOfType={props.roles}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Supporting"
              items={activeSupportLinks}
              allItemsOfType={props.roles}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Notified"
              items={activeNotifyLinks}
              allItemsOfType={props.roles}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
          </CardGroup>
        </NodeCard>
      );
    }
    if (props.activeNode.itemtype === "object") {
      return (
        <NodeCard
          activeNode={props.activeNode}
          setActiveNode={props.setActiveNode}
          nodeData={props.nodeData}
          setNodeData={props.setNodeData}
          linkData={props.linkData}
          setLinkData={props.setLinkData}
          objects={props.objects}
          setObjects={props.setObjects}
          setLinkDataDetail={props.setLinkDataDetail}
        >
          <CardGroup>
            <LinkCard
              linkType="Used in Activities"
              items={activeUsedIn}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Produced or Modified in Activities"
              items={activeProducedIn}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
          </CardGroup>
        </NodeCard>
      );
    }
    if (props.activeNode.itemtype === "role") {
      return (
        <NodeCard
          activeNode={props.activeNode}
          setActiveNode={props.setActiveNode}
          nodeData={props.nodeData}
          setNodeData={props.setNodeData}
          linkData={props.linkData}
          setLinkData={props.setLinkData}
          roles={props.roles}
          setRoles={props.setRoles}
          setLinkDataDetail={props.setLinkDataDetail}
        >
          <CardGroup>
            <LinkCard
              linkType="Responsible for Activities"
              items={activeResponsibleFor}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Supporting in Activities"
              items={activeSupportIn}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
            <LinkCard
              linkType="Notified in Activities"
              items={activeNotifyIn}
              allItemsOfType={props.activities}
              activeNode={props.activeNode}
              linkData={props.linkData}
              setLinkData={props.setLinkData}
              setLinkDataDetail={props.setLinkDataDetail}
              displayEdit={true}
            />
          </CardGroup>
        </NodeCard>
      );
    }
  }
  if (nodeData.length == 0) {
    return (
      <React.Fragment>
        <h4 className="p-2">
          After items are added, click on them in the graph and see their
          details here!
        </h4>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <h4 className="p-2">
          Click on an item in the graph to see its details!
        </h4>
      </React.Fragment>
    );
  }
}

export default ItemDetails;
