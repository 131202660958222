import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

function CenterBoxContainer(props) {
  const boxWidth = {
    minWidth: "30%",
  };
  return (
    <Container className="h-100 d-flex justify-content-center align-items-center bg-primary mw-100">
      <Card className="border rounded shadow-lg bg-white" style={boxWidth}>
        <Card.Header className="text-left text-primary bg-light" as="h5">
          bpviz
        </Card.Header>
        {props.children}
      </Card>
    </Container>
  );
}

export default CenterBoxContainer;
