import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import Select from "react-select";
import PmContext from "../context/pmContext";
import AuthContext from "../context/authContext";

function ActivitytoProcessInput(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { processes, getProcesses, loading } = useContext(PmContext);
  const [options, setOptions] = useState(
    processes.map((process, index) => ({
      key: index,
      value: process.process_id,
      label: process.process_name,
    }))
  );

  const nonAuthOptions = () => {
    return processes.map((process, index) => ({
      key: index,
      value: process.process_id,
      label: process.process_name,
    }));
  };

  let defaultVal = options.filter(
    (processChoice) => processChoice.label === props.activeNode.process
  );
  if (defaultVal.length === 0) {
    defaultVal = [];
  }
  const handleChange = (change) => {
    props.setValue("process", change, {
      shouldDirty: true,
    });
  };
  //When adding a new activity
  if (props.newOrEdit === "n") {
    return (
      <Form.Group controlId="">
        <Form.Label>Belongs to Process</Form.Label>
        <Controller
          as={
            <Select
              closeMenuOnSelect={true}
              options={nonAuthOptions()}
              defaultValue=""
              onChange={handleChange}
              className="basic-single"
              classNamePrefix="select"
            />
          }
          control={props.formCont}
          name="process"
        />
      </Form.Group>
    );
  }
  //When editing an existing activity
  else {
    return (
      <Form.Group controlId="">
        <Form.Label>Belongs to Process</Form.Label>
        <Controller
          as={
            <Select
              closeMenuOnSelect={true}
              options={options}
              defaultValue={defaultVal[0]}
              onChange={handleChange}
              className="basic-single"
              classNamePrefix="select"
            />
          }
          control={props.formCont}
          name="process"
          defaultValue={defaultVal[0]}
        />
      </Form.Group>
    );
  }
}

export default ActivitytoProcessInput;
