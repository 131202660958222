import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import Error404 from "./error404";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import PublicSharedContainer from "../layout/publicSharedContainer";
import PublicSharedGraphContainer from "../layout/publicSharedGraphContainer";
import PublicSharedNodeCard from "../descriptors/publicSharedNodeCard";
import PublicSharedCards from "../descriptors/publicSharedCards";
import LoginRegisterLoader from "../loaders/loginRegisterLoader";

function PublicShared(props) {
  document.title = "bpviz | shared diagram";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [loading, setLoading] = useState(false);
  const [pageExists, setPageExists] = useState();
  const [activeNode, setActiveNode] = useState([]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [createdTime, setCreatedTime] = useState();
  const [lastUpdated, setLastUpdated] = useState();
  const [tags, setTags] = useState([]);
  const [username, setUsername] = useState();
  const [nodes, setNodes] = useState([]);
  const [links, setLinks] = useState([]);
  const [hoverProcess, setHoverProcess] = useState([]);
  const [isoProcess, setIsoProcess] = useState();
  const [linkSpacing, setLinkSpacing] = useState(42.5);

  function flipIsolateFlag(data) {
    setHoverProcess([]);
    if (!isoProcess) {
      return setIsoProcess(data);
    } else if (isoProcess !== data) {
      setIsoProcess(data);
    } else {
      return setIsoProcess();
    }
  }

  //Get network ID from URL
  let { network_id } = useParams();

  //Create axios instance
  const axiosSharedData = axios.create();

  // Add a request interceptor
  axiosSharedData.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosSharedData.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  const getNetworkDataApi = {
    url: "/public-share/get-network/" + network_id,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "GET",
  };
  async function getNetworkData() {
    await axiosSharedData(getNetworkDataApi)
      .then((response) => {
        if (response.data.length === 0) {
          setPageExists(false);
        } else {
          setPageExists(true);
          const resData = response.data;
          setNodes(resData.node_data);
          setLinks(resData.link_data);
          setTitle(resData.title);
          setDescription(resData.description);
          setCreatedTime(resData.created);
          setLastUpdated(resData.last_updated);
          setTags(resData.tags);
          setUsername(resData.username);
          setActiveNode([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getNetworkData();
  }, []);

  function displayDetails() {
    if (activeNode.length === 0) {
      return { display: "none" };
    } else {
      return { display: "block" };
    }
  }

  function getProcessList() {
    let uniqueList = [];
    nodes.forEach((node) => {
      if (node.process) {
        //If string
        if (typeof node.process === "string") {
          if (uniqueList.length > 0) {
            if (!uniqueList.includes(node.process)) {
              uniqueList.push(node.process);
            }
          } else {
            uniqueList.push(node.process);
          }
        }
        //If array
        if (Array.isArray(node.process) === true && node.process.length > 0) {
          if (uniqueList.length > 0) {
            node.process.forEach((p) => {
              if (!uniqueList.includes(p)) {
                uniqueList.push(p);
              }
            });
          } else {
            node.process.forEach((p) => {
              uniqueList.push(p);
            });
          }
        }
      }
    });
    return uniqueList;
  }

  const processButtons = getProcessList().map((process, index) => {
    return (
      <Button
        variant="outline-primary"
        size="sm"
        className="mx-1"
        key={index}
        onMouseEnter={() => setHoverProcess([process])}
        onMouseLeave={() => setHoverProcess([])}
        onClick={() => flipIsolateFlag(process)}
      >
        {process}
      </Button>
    );
  });

  const formattedTags = tags
    ? tags
        .toString()
        .split(",")
        .map((tag, index) => {
          return (
            <li
              className="d-inline mx-1 p-1 bg-secondary text-light"
              key={index}
            >
              {"#" + tag.trim()}
            </li>
          );
        })
    : "";

  function formatDateTime(timestamp) {
    const dbDateValue = new Date(timestamp).toLocaleDateString();
    const dbTimeValue = new Date(timestamp).toLocaleTimeString([], {
      timeStyle: "short",
    });
    return dbDateValue + " at " + dbTimeValue;
  }

  if (pageExists === false) {
    return <Error404 />;
  } else {
    if (!loading) {
      return (
        <PublicSharedContainer>
          <h5 className="m-0 p-0">{title}</h5>
          <p className="m-0 p-0">Created by user / {username}</p>
          <p className="m-0 p-0">Last Updated: {formatDateTime(lastUpdated)}</p>
          <p className="m-0 p-0">Created: {formatDateTime(createdTime)}</p>
          <p className="my-1 p-0">{formattedTags}</p>
          <p className="mb-1 p-0">{description}</p>
          <div>{processButtons}</div>
          <Container fluid className="h-100 p-0">
            <div className="h-100 shadow-sm bg-white">
              <PublicSharedGraphContainer
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                activeProcess={hoverProcess}
                nodes={nodes}
                links={links}
                isoProcess={isoProcess}
                linkSpacing={linkSpacing}
                setLinkSpacing={setLinkSpacing}
              />
            </div>
          </Container>
          <Navbar
            fixed="bottom"
            bg="light"
            className="h-50 overflow-auto border-top"
            style={displayDetails()}
          >
            <div className="d-block text-right pb-1">
              <svg
                style={{ cursor: "pointer" }}
                onClick={() => setActiveNode([])}
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-x-circle-fill"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
                />
              </svg>
            </div>
            <PublicSharedNodeCard activeNode={activeNode}>
              <PublicSharedCards activeNode={activeNode} linkData={links} />
            </PublicSharedNodeCard>
          </Navbar>
        </PublicSharedContainer>
      );
    } else {
      return (
        <PublicSharedContainer>
          <LoginRegisterLoader />
        </PublicSharedContainer>
      );
    }
  }
}

export default PublicShared;
