import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

function HighlightProcessAction(props) {
  const [highlighted, setHighlighted] = useState("Highlight");
  let activeProcesses = [...props.activeProcess];
  useEffect(() => {
    if (activeProcesses.includes(props.process)) {
      setHighlighted("Un-Highlight");
    }
  }, [activeProcesses]);
  const handleHighlight = (item) => {
    if (highlighted === "Un-Highlight") {
      const removedProcess = activeProcesses.filter(
        (process) => process !== item
      );
      setHighlighted("Highlight");
      props.setActiveProcess(removedProcess);
    }
    if (highlighted === "Highlight") {
      activeProcesses.push(item);
      setHighlighted("Un-Highlight");
      props.setActiveProcess(activeProcesses);
    }
  };
  return (
    <Dropdown.Item onClick={() => handleHighlight(props.process)}>
      {highlighted}
    </Dropdown.Item>
  );
}

export default HighlightProcessAction;
