import React, { useState, useEffect } from "react";
import axios from "axios";
import PmContext from "./pmContext";

const PmContextProvider = (props) => {
  const [loading, setLoading] = useState(false);
  //process state
  const [processes, setProcesses] = useState([]);
  const resetAllState = () => {
    setProcesses([]);
    setActivities([]);
    setObjects([]);
    setRoles([]);
    setNodeData([]);
    setLinkData([]);
  };
  //activity state
  const [activities, setActivities] = useState([]);
  //object state
  const [objects, setObjects] = useState([]);
  //role state
  const [roles, setRoles] = useState([]);
  //all nodes state
  const [nodeData, setNodeData] = useState([]);
  //all item links state
  const [linkData, setLinkData] = useState([]);

  //Create axios instance
  const axiosPm = axios.create();

  // Add a request interceptor
  axiosPm.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosPm.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  //Get stuff from databse for registered users
  const getProcessApi = {
    url: "process-model/get-processes",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getProcesses() {
    await axiosPm(getProcessApi)
      .then((response) => {
        const resData = response.data;
        setProcesses(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  const getActivitiesApi = {
    url: "process-model/get-activities",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getActivities() {
    await axiosPm(getActivitiesApi)
      .then((response) => {
        const resData = response.data;
        setActivities(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  const getObjectsApi = {
    url: "process-model/get-objects",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getObjects() {
    await axiosPm(getObjectsApi)
      .then((response) => {
        const resData = response.data;
        setObjects(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  const getRolesApi = {
    url: "process-model/get-roles",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getRoles() {
    await axiosPm(getRolesApi)
      .then((response) => {
        const resData = response.data;
        setRoles(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  const getNodesApi = {
    url: "process-model/get-all-nodes",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getNodeData() {
    await axiosPm(getNodesApi)
      .then((response) => {
        const resData = response.data;
        setNodeData(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  const getLinksApi = {
    url: "process-model/get-all-links",
    method: "GET",
    headers: {
      token: localStorage.token,
    },
  };
  async function getLinkData() {
    await axiosPm(getLinksApi)
      .then((response) => {
        const resData = response.data;
        setLinkData(resData);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }
  useEffect(() => {
    getProcesses();
    getActivities();
    getObjects();
    getRoles();
    getNodeData();
    getLinkData();
  }, []);

  return (
    <PmContext.Provider
      value={{
        processes,
        setProcesses,
        getProcesses,
        activities,
        setActivities,
        getActivities,
        objects,
        setObjects,
        getObjects,
        roles,
        setRoles,
        getRoles,
        nodeData,
        setNodeData,
        getNodeData,
        linkData,
        setLinkData,
        getLinkData,
        loading,
        resetAllState,
      }}
    >
      {props.children}
    </PmContext.Provider>
  );
};

export default PmContextProvider;
