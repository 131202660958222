import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function PublicSharedContainer(props) {
  return (
    <Container className="h-100 py-2 d-flex justify-content-center align-items-center bg-primary mw-100">
      <Card className="h-100 w-100 border rounded shadow-lg bg-white">
        <Card.Header className="text-left text-primary bg-light" as="h5">
          <Link to="/">bpviz</Link>
        </Card.Header>
        {props.children}
      </Card>
    </Container>
  );
}

export default React.memo(PublicSharedContainer);
