import React, { useState, useContext } from "react";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import Dropdown from "react-bootstrap/Dropdown";
import HighlightProcessAction from "./highlightProcessAction";
import IsolateProcessAction from "./isolateProcessAction";
import EditProcessForm from "../form/editProcessForm";
import DeleteProcessAction from "../form/deleteProcessAction";
import CollectionLoader from "../loaders/collectionLoader";

function ProcessStream(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { loading, processes } = useContext(PmContext);
  const [editMode, setEditMode] = useState({
    inEdit: false,
    process: "",
  });

  const anonProcesses = props.processes.map((process, index) => {
    if (!editMode.inEdit) {
      return (
        <Dropdown key={index} className="d-inline mx-1">
          <Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-basic"
            size="sm"
            className="my-1"
          >
            {process.processname}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <HighlightProcessAction
              activeProcess={props.activeProcess}
              setActiveProcess={props.setActiveProcess}
              process={process.processname}
            />
            <IsolateProcessAction
              process={process.process_name}
              isoActiveProcess={props.isoActiveProcess}
              setIsoActiveProcess={props.setIsoActiveProcess}
            />
            <EditProcessForm
              editMode={editMode}
              setEditMode={setEditMode}
              process={process.processname}
              isoActiveProcess={props.isoActiveProcess}
              setIsoActiveProcess={props.setIsoActiveProcess}
            />
            <DeleteProcessAction
              nodeData={props.nodeData}
              setNodeData={props.setNodeData}
              processName={process.processname}
              activeProcess={props.activeProcess}
              setActiveProcess={props.setActiveProcess}
              activeNode={props.activeNode}
              setActiveNode={props.setActiveNode}
              processes={props.processes}
              setProcesses={props.setProcesses}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      if (editMode.process === process.processname) {
        return (
          <EditProcessForm
            key={process.processname}
            editMode={editMode}
            setEditMode={setEditMode}
            processes={props.processes}
            setProcesses={props.setProcesses}
            process={process.processname}
            processIndex={index}
            activeProcess={props.activeProcess}
            setActiveProcess={props.setActiveProcess}
            isoActiveProcess={props.isoActiveProcess}
            setIsoActiveProcess={props.setIsoActiveProcess}
            nodeData={props.nodeData}
            setNodeData={props.setNodeData}
            activeNode={props.activeNode}
            setActiveNode={props.setActiveNode}
          />
        );
      }
    }
  });

  const regUserProcesses = processes.map((process, index) => {
    if (!editMode.inEdit) {
      return (
        <Dropdown key={index} className="d-inline mx-1">
          <Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-basic"
            size="sm"
            className="my-1"
          >
            {process.process_name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <HighlightProcessAction
              activeProcess={props.activeProcess}
              setActiveProcess={props.setActiveProcess}
              process={process.process_name}
            />
            <IsolateProcessAction
              process={process.process_name}
              isoActiveProcess={props.isoActiveProcess}
              setIsoActiveProcess={props.setIsoActiveProcess}
            />
            <EditProcessForm
              editMode={editMode}
              setEditMode={setEditMode}
              process={process.process_name}
              isoActiveProcess={props.isoActiveProcess}
              setIsoActiveProcess={props.setIsoActiveProcess}
            />
            <DeleteProcessAction
              nodeData={props.nodeData}
              setNodeData={props.setNodeData}
              processId={process.process_id}
              processName={process.process_name}
              activeProcess={props.activeProcess}
              setActiveProcess={props.setActiveProcess}
              activeNode={props.activeNode}
              setActiveNode={props.setActiveNode}
              processes={props.processes}
              setProcesses={props.setProcesses}
            />
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      if (editMode.process === process.process_name) {
        return (
          <EditProcessForm
            key={index}
            editMode={editMode}
            setEditMode={setEditMode}
            processes={props.processes}
            setProcesses={props.setProcesses}
            isoActiveProcess={props.isoActiveProcess}
            setIsoActiveProcess={props.setIsoActiveProcess}
            process={process.process_name}
            processId={process.process_id}
            processIndex={index}
            activeProcess={props.activeProcess}
            setActiveProcess={props.setActiveProcess}
            nodeData={props.nodeData}
            setNodeData={props.setNodeData}
            activeNode={props.activeNode}
            setActiveNode={props.setActiveNode}
          />
        );
      }
    }
  });

  if (props.processes.length > 0) {
    //If user logged in
    if (isAuthenticated && loading) {
      return (
        <React.Fragment>
          <CollectionLoader />
        </React.Fragment>
      );
    } else if (isAuthenticated && !loading) {
      return (
        <React.Fragment>Process Collection: {regUserProcesses}</React.Fragment>
      );
    } else {
      //If user not logged in/registered
      return (
        <React.Fragment>Process Collection: {anonProcesses}</React.Fragment>
      );
    }
  } else {
    return "Process Collection: Add a process and it will appear here";
  }
}

export default ProcessStream;
