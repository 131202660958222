import React from "react";
import Button from "react-bootstrap/Button";

function VerticalCollapseButton(props) {
  return (
    <Button
      onClick={() => props.stateSetter(!props.state)}
      aria-controls="task-associations"
      aria-expanded={props.state}
      className="w-75 border shadow-sm btn-light"
    >
      {props.label}
    </Button>
  );
}

export default VerticalCollapseButton;
