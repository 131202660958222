import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CenterBoxContainer from "../layout/centerBoxContainer";
import LoginRegisterLoader from "../loaders/loginRegisterLoader";

function Register(props) {
  document.title = "bpviz | register";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { register, handleSubmit } = useForm({});
  const [errMsgs, setErrMsgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [googleUrl, setGoogleUrl] = useState();
  const errsFromServer = errMsgs.map((error, index) => {
    return <li key={index}>{error.msg}</li>;
  });
  //Create axios instance
  const axiosRegister = axios.create();

  // Add a request interceptor
  axiosRegister.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosRegister.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );
  const registerUser = async (data) => {
    const apiOptionsUser = {
      url: "/users/register",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    const apiOptionsClient = {
      url: "/client/create-client",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    const apiOptionsUserToClient = {
      url: "/users/add-to-client",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    const apiOptionsClientSchema = {
      url: "/client/create-client-schema",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    //create user
    await axiosRegister(apiOptionsUser)
      .then(async (response) => {
        const userId = response.data.userId;
        data.userId = userId;
        //create client
        await axiosRegister(apiOptionsClient);
        //assign user to client
        await axiosRegister(apiOptionsUserToClient).then(async (response) => {
          const clientId = response.data.clientId;
          data.clientId = clientId;
          localStorage.setItem("token", response.data.token);
          apiOptionsClientSchema.headers.token = response.data.token;
          //create schema for client
          await axiosRegister(apiOptionsClientSchema);
          await props.setAuth(true);
        });
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsgs(error.response.data);
      });
  };

  async function getGoogleUrl() {
    const apiGoogleUser = {
      url: "/users/get-google-url",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      // data: data,
    };
    await axiosRegister(apiGoogleUser)
      .then(async (req, res) => {
        setGoogleUrl(req.data);
        // console.log(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        // setErrMsgs(error.response);
      });
  }

  useEffect(() => {
    getGoogleUrl();
  }, []);

  if (!loading) {
    return (
      <CenterBoxContainer>
        <div className="border-bottom">
          <Button
            variant="outline-secondary"
            className="mx-5 my-3"
            href={googleUrl}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-google mx-2"
              viewBox="0 0 16 16"
            >
              <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
            </svg>
            Login with Google
          </Button>
        </div>
        <Form className="p-4" onSubmit={handleSubmit(registerUser)}>
          <Form.Group controlId="formBasicUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="name"
              placeholder="Enter username"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Enter email"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="formBasicConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              name="password2"
              type="password"
              placeholder="Password"
              ref={register}
            />
          </Form.Group>
          <Button type="submit" variant="spotlight">
            Register
          </Button>
          <Form.Text className="text-left text-danger">
            {errsFromServer}
          </Form.Text>
          <Form.Text className="text-muted">
            Already joined? <Link to="/login">Login</Link>
          </Form.Text>
        </Form>
      </CenterBoxContainer>
    );
  } else {
    return (
      <CenterBoxContainer>
        <LoginRegisterLoader />
        Setting stuff up...
      </CenterBoxContainer>
    );
  }
}

export default Register;
