import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import LinkListGroup from "./linkListGroup";
import UpdateLinkForm from "../form/updateLinkForm";
import LinkCardEditBtn from "./linkCardEditBtn";

//On edit button click, display multiSelectInput
//Pass in current links as defaultValue prop
//Need to  create an updateLinkForm to register input (link changes)

function LinkCard(props) {
  const activeNode = props.activeNode;
  const [linkEditMode, setLinkEditMode] = useState(false);
  if (linkEditMode) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>{props.linkType}</Card.Title>
          <UpdateLinkForm
            setLinkEditMode={setLinkEditMode}
            items={props.items}
            allItemsOfType={props.allItemsOfType}
            setLinkEditMode={setLinkEditMode}
            activeNode={props.activeNode}
            linkData={props.linkData}
            setLinkData={props.setLinkData}
            linkDataDetail={props.linkDataDetail}
            setLinkDataDetail={props.setLinkDataDetail}
            linkType={props.linkType}
          />
        </Card.Body>
      </Card>
    );
  }
  if (props.items.length === 0) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>{props.linkType}</Card.Title>
          <LinkCardEditBtn
            display={props.displayEdit}
            setLinkEditMode={setLinkEditMode}
          />
          <p>There's nothing here :/</p>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <Card.Body>
          <Card.Title>{props.linkType}</Card.Title>
          <LinkCardEditBtn
            display={props.displayEdit}
            setLinkEditMode={setLinkEditMode}
          />
          <LinkListGroup items={props.items} activeNode={props.activeNode} />
        </Card.Body>
      </Card>
    );
  }
}

export default LinkCard;
