import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Header from "../layout/header";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import ListGroup from "react-bootstrap/ListGroup";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
import improve_processes from "../../media/improve_processes.svg";
import transfer_knowledge from "../../media/transfer_knowledge.svg";
import define_requirements from "../../media/define_requirements.svg";
import bpviz_visualizer_demo from "../../media/bpviz_visualizer_demo.mp4";

function HomePage(props) {
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <React.Fragment>
      <Header></Header>
      <Container className="mainContRow">
        <h1 className="display-4">Build Your Business Process Network</h1>
        <h3 className="text-muted">
          Quickly visualize activities, objects, roles, and their interactions
          with bpviz
        </h3>
        <Container className="my-4">
          <CardDeck>
            <Card style={{ border: "none" }}>
              <div style={{ width: "100%", height: "auto", margin: "auto" }}>
                <ResponsiveEmbed className="shadow" aspectRatio="16by9">
                  <embed
                    type="video/mp4"
                    className="border"
                    src={bpviz_visualizer_demo}
                  />
                </ResponsiveEmbed>
              </div>
            </Card>
            <Card className="my-auto" style={{ border: "none" }}>
              <Link to="/visualizer">
                <Button className="btn-lg btn-spotlight">Try bpviz Now</Button>
              </Link>
              <p className="text-muted">No Registration Required</p>
            </Card>
          </CardDeck>
        </Container>
        <Container>
          <div className="my-5">
            <Card style={{ border: "none" }}>
              <Card.Title>bpviz features</Card.Title>
              <ListGroup className="font-weight-light" variant="flush">
                <ListGroup.Item>
                  Create business process networks (an integrated model of
                  activities, objects, and roles)
                </ListGroup.Item>
                <ListGroup.Item>
                  Highlight and isolate activities, objects, and roles by
                  process
                </ListGroup.Item>
                <ListGroup.Item>
                  Click and drag, or scroll to zoom for business process
                  visualization analysis
                </ListGroup.Item>
                <ListGroup.Item>
                  Export business process data to spreadsheets (i.e. Excel)
                </ListGroup.Item>
                <ListGroup.Item>
                  Download business process visualizations as images (.PNG,
                  .SVG)
                </ListGroup.Item>
                <ListGroup.Item>
                  Share filtered business process newtorks with others
                </ListGroup.Item>
                <ListGroup.Item>
                  Create new versions of shared business process networks
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </Container>
        <CardDeck className="mb-5">
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Continuously Improve Processes</Card.Title>
              <Card.Img
                variant="bottom"
                src={improve_processes}
                className="py-1"
              />
              <Card.Text>
                Gain feedback from process operators by making documentation
                visible. The more accessible it is, the more likely it is to be
                referenced and discussed.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Transfer Knowledge</Card.Title>
              <Card.Img
                variant="bottom"
                src={transfer_knowledge}
                className="py-1"
              />
              <Card.Text>
                Looking to scale up? Centrally located process information can
                easily be shared virtually anywhere. Turn documentation into a
                valuable asset.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title>Define Better Requirements</Card.Title>
              <Card.Img variant="bottom" src={define_requirements} />
              <Card.Text>
                Bridge the gap between technical and business communication.
                Process models help show stakeholder expectations for who should
                be doing what (and when) within a system.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
      </Container>
    </React.Fragment>
  );
}

export default HomePage;
