import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ActivitytoProcessInput from "../form/activityProcessInput";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";

function setItemId(item) {
  if (item.itemtype === "activity") {
    return "act_" + item.itemid;
  } else if (item.itemtype === "object") {
    return "obj_" + item.itemid;
  } else if (item.itemtype === "role") {
    return "rle_" + item.itemid;
  }
}

function NodeCard(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const {
    nodeData,
    linkData,
    getNodeData,
    getLinkData,
    getActivities,
    setActivities,
    activities,
    getObjects,
    setObjects,
    objects,
    getRoles,
    setRoles,
    roles,
    setNodeData,
    setLinkData,
  } = useContext(PmContext);
  function idPreface(item, idx) {
    if (item.itemtype === "activity") {
      return "act_" + idx;
    }
    if (item.itemtype === "object") {
      return "obj_" + idx;
    }
    if (item.itemtype === "role") {
      return "rle_" + idx;
    }
  }
  const [editMode, setEditMode] = useState(false);
  const { register, handleSubmit, setValue, control, watch } = useForm();
  const [errMsgs, setErrMsgs] = useState([]);
  const watchName = watch("itemname", props.activeNode.itemname);
  const errMsgTextStyle = () => {
    if (watchName.length > 50) {
      return "text-danger";
    }
  };
  const activeItemId = props.activeNode.itemid.split("_")[1];
  function processDisplay(node) {
    if (node.itemtype === "activity") {
      if (node.process === null) {
        return "Belongs to Process: " + "";
      } else {
        return "Belongs to Process: " + node.process;
      }
    } else {
      return;
    }
  }
  async function removeItem(node) {
    if (!isAuthenticated) {
      let mappedNodeData = nodeData.map((item, index) => {
        return Object.assign({}, item, {
          itemid: idPreface(item, index),
        });
      });
      mappedNodeData = mappedNodeData.filter(
        (item) => item.itemid !== node.itemid
      );

      let newNodeData = mappedNodeData.map((item, index) => {
        return {
          itemidOld: item.itemid,
          itemidNew: idPreface(item, index),
          itemname: item.itemname,
          itemtype: item.itemtype,
          process: item.process,
        };
      });

      const filteredLinkData = linkData.filter(
        (item) =>
          ![item.link_source_id, item.link_target_id].includes(node.itemid)
      );
      //look up old item ids within filtered link data, replace with new ids
      newNodeData.forEach((node) => {
        filteredLinkData.forEach((link, index) => {
          if (node.itemidOld === link.link_source_id) {
            filteredLinkData[index].link_source_id = node.itemidNew;
          }
          if (node.itemidOld === link.link_target_id) {
            filteredLinkData[index].link_target_id = node.itemidNew;
          }
        });
      });

      setNodeData(newNodeData);
      setLinkData(filteredLinkData);

      if (node.itemtype === "activity") {
        setActivities(
          activities.filter((item) => item.itemname !== node.itemname)
        );
      }
      if (node.itemtype === "object") {
        setObjects(objects.filter((item) => item.itemname !== node.itemname));
      }
      if (node.itemtype === "role") {
        setRoles(roles.filter((item) => item.itemname !== node.itemname));
      }
      //Reset item details box
      props.setActiveNode([]);
    } else {
      const deleteActivityApi = {
        url: "process-model/delete-activity",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { activity_id: activeItemId },
      };
      const deleteObjectApi = {
        url: "process-model/delete-object",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { object_id: activeItemId },
      };
      const deleteRoleApi = {
        url: "process-model/delete-role",
        method: "POST",
        headers: {
          token: localStorage.token,
        },
        data: { role_id: activeItemId },
      };
      if (node.itemtype === "activity") {
        await axios(deleteActivityApi)
          .then(async (response) => {
            const resData = response.data;
            //Refresh main node listing
            await getLinkData();
            await getNodeData();
            await getActivities();
            props.setActiveNode([]);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(
              "Oops. Something went wrong. Try refreshing the page and trying again."
            );
          });
      }
      if (node.itemtype === "object") {
        await axios(deleteObjectApi)
          .then(async (response) => {
            const resData = response.data;
            //Refresh main node listing
            await getLinkData();
            await getNodeData();
            await getObjects();
            props.setActiveNode([]);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(
              "Oops. Something went wrong. Try refreshing the page and trying again."
            );
          });
      }
      if (node.itemtype === "role") {
        await axios(deleteRoleApi)
          .then(async (response) => {
            const resData = response.data;
            //Refresh main node listing
            await getLinkData();
            await getNodeData();
            await getRoles();
            props.setActiveNode([]);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(
              "Oops. Something went wrong. Try refreshing the page and trying again."
            );
          });
      }
    }
  }

  async function updateItem(node) {
    if (!isAuthenticated) {
      //Update main node listing
      let newNodeList = [...nodeData];
      newNodeList[node.itemindex].itemname = node.itemname;
      newNodeList[node.itemindex].itemid = setItemId(node);
      if (newNodeList[node.itemindex].process === undefined) {
        newNodeList[node.itemindex].process = {};
        newNodeList[node.itemindex].process.key = "";
        newNodeList[node.itemindex].process.value = "";
        newNodeList[node.itemindex].process.label = "";
      } else if (newNodeList[node.itemindex].process === "" && node.process) {
        newNodeList[node.itemindex].process = {};
        newNodeList[node.itemindex].process.key = node.process.key;
        newNodeList[node.itemindex].process.value = node.process.value;
        newNodeList[node.itemindex].process.label = node.process.label;
      } else if (newNodeList[node.itemindex].process === "") {
        newNodeList[node.itemindex].process = {};
        newNodeList[node.itemindex].process.key = "";
        newNodeList[node.itemindex].process.value = "";
        newNodeList[node.itemindex].process.label = "";
      } else {
        newNodeList[node.itemindex].process.label = node.process.label;
      }

      //Update link associations
      // let newLinkList = [...linkData];
      // newLinkList.forEach((i, index, newLinkList) => {
      //   if (i.linkSource === node.olditemname) {
      //     newLinkList[index] = {
      //       linkSource: node.itemname,
      //       linkTarget: newLinkList[index].linkTarget,
      //       type: newLinkList[index].type,
      //     };
      //   }
      //   if (i.linkTarget === node.olditemname) {
      //     newLinkList[index] = {
      //       linkSource: newLinkList[index].linkSource,
      //       linkTarget: node.itemname,
      //       type: newLinkList[index].type,
      //     };
      //   }
      // });

      let newLinkList = [...linkData];
      newLinkList.forEach((i, index, newLinkList) => {
        if (i.link_source_name === node.olditemname) {
          newLinkList[index] = {
            link_source_id: i.link_source_id,
            link_source_name: node.itemname,
            link_target_id: newLinkList[index].link_target_id,
            link_target_name: newLinkList[index].link_target_name,
            link_type: newLinkList[index].link_type,
            // linkSource: node.itemname,
            // linkTarget: newLinkList[index].linkTarget,
            // type: newLinkList[index].type,
          };
        }
        if (i.link_target_name === node.olditemname) {
          newLinkList[index] = {
            link_source_id: i.link_source_id,
            link_source_name: newLinkList[index].link_source_name,
            link_target_id: newLinkList[index].link_target_id,
            link_target_name: node.itemname,
            link_type: newLinkList[index].link_type,
            // linkSource: newLinkList[index].linkSource,
            // linkTarget: node.itemname,
            // type: newLinkList[index].type,
          };
        }
      });

      setLinkData(newLinkList);
      // props.setLinkDataDetail(newLinkList);
      setNodeData(newNodeList);

      //Update active node
      props.setActiveNode({
        index: node.itemindex,
        itemid: setItemId(node),
        itemname: node.itemname,
        itemtype: node.itemtype,
        process: newNodeList[node.itemindex].process.label,
      });
      setEditMode(false);
    } else {
      let activityProcess = "";
      if (node.process !== undefined) {
        activityProcess = node.process.value;
      } else if (node.process === undefined || node.process === "") {
        node.process = {};
        node.process.key = "";
        node.process.value = "";
        node.process.label = "";
      }
      setErrMsgs();
      if (node.itemtype === "activity") {
        const itemGraphId = "act_" + node.itemid;
        const updateActivityApi = {
          url: "process-model/update-activity",
          method: "POST",
          headers: {
            token: localStorage.token,
          },
          data: { name: node.itemname, activity_id: node.itemid },
        };
        const updateActivityToProcessApi = {
          url: "process-model/update-act-pro-link",
          method: "POST",
          headers: {
            token: localStorage.token,
          },
          data: { process_id: activityProcess, activity_id: node.itemid },
        };
        //Name change and activity to process change
        if (
          node.itemname !== node.olditemname &&
          node.oldprocessname !== node.process.label
        ) {
          await axios(updateActivityApi)
            .then(async (response) => {
              const resData = response.data;
              await axios(updateActivityToProcessApi)
                .then(async (response) => {
                  //Refresh main node listing
                  await getNodeData();
                  await getLinkData();
                  await getActivities();
                  setEditMode(false);
                })
                .catch((error) => {
                  console.log(error.response);
                  setErrMsgs(error.response.data[0].msg);
                });
            })
            .catch((error) => {
              console.log(error.response);
              setErrMsgs(error.response.data[0].msg);
            });
        }
        //Name change only
        else if (
          node.itemname !== node.olditemname &&
          node.oldprocessname === node.process.label
        ) {
          await axios(updateActivityApi)
            .then(async (response) => {
              await getNodeData();
              await getLinkData();
              await getActivities();
              setEditMode(false);
            })
            .catch((error) => {
              console.log(error.response);
              setErrMsgs(error.response.data[0].msg);
            });
        }
        //Activity to process change only
        else if (
          node.itemname === node.olditemname &&
          node.oldprocessname !== node.process.label
        ) {
          await axios(updateActivityToProcessApi)
            .then(async (response) => {
              const resData = response.data;
              //Refresh main node listing
              await getNodeData();
              await getLinkData();
              await getActivities();
              setEditMode(false);
            })
            .catch((error) => {
              console.log(error.response);
              setErrMsgs(error.response.data[0].msg);
            });
        }
        //No changes at all, with process
        else if (
          node.itemname === node.olditemname &&
          node.oldprocessname === node.process.label
        ) {
          setEditMode(false);
        }
        //No changes as all, no process
        else if (node.process.label === "") {
          setEditMode(false);
        }
        props.setActiveNode({
          itemid: itemGraphId,
          itemname: node.itemname,
          itemtype: "activity",
          process: node.process.label,
        });
      }
      if (node.itemtype === "object") {
        const itemGraphId = "obj_" + node.itemid;
        const updateObjectApi = {
          url: "process-model/update-object",
          method: "POST",
          headers: {
            token: localStorage.token,
          },
          data: { objectname: node.itemname, object_id: node.itemid },
        };
        await axios(updateObjectApi)
          .then(async (response) => {
            const resData = response.data;
            //Refresh main node listing
            await getNodeData();
            await getLinkData();
            await getObjects();
            setEditMode(false);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(error.response.data[0].msg);
          });
        props.setActiveNode({
          itemid: itemGraphId,
          itemname: node.itemname,
          itemtype: "object",
          process: "",
        });
      }
      if (node.itemtype === "role") {
        const itemGraphId = "rle_" + node.itemid;
        const updateRoleApi = {
          url: "process-model/update-role",
          method: "POST",
          headers: {
            token: localStorage.token,
          },
          data: { rolename: node.itemname, role_id: node.itemid },
        };
        await axios(updateRoleApi)
          .then(async (response) => {
            const resData = response.data;
            //Refresh main node listing
            await getNodeData();
            await getLinkData();
            await getRoles();
            setEditMode(false);
          })
          .catch((error) => {
            console.log(error.response);
            setErrMsgs(error.response.data[0].msg);
          });
        props.setActiveNode({
          itemid: itemGraphId,
          itemname: node.itemname,
          itemtype: "role",
          process: "",
        });
      }
    }
  }
  const processInputDisplay = (node) => {
    if (node.itemtype === "activity") {
      return (
        <ActivitytoProcessInput
          activeNode={props.activeNode}
          formCont={control}
          setValue={setValue}
          regRef={register}
        />
      );
    } else {
      // return <p>Processes Involved in (placeholder)</p>;
    }
  };

  if (!editMode) {
    return (
      <div>
        <Card className="mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
          <Card.Body>
            <Card.Title>{props.activeNode.itemname}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {props.activeNode.itemtype} <br></br>
              {processDisplay(props.activeNode)}
              {/* Belongs to Process: {props.activeNode.process} */}
            </Card.Subtitle>
            <Button
              variant="outline-primary"
              className="mx-1 btn-sm"
              onClick={() => setEditMode(true)}
            >
              Edit
            </Button>
            <Button
              variant="outline-danger"
              className="mx-1 btn-sm"
              onClick={() => removeItem(props.activeNode)}
            >
              Delete
            </Button>
          </Card.Body>
        </Card>
        {props.children}
      </div>
    );
  } else {
    return (
      <div>
        <Card className="mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0">
          <Card.Body>
            <Form id="node-edit-form" onSubmit={handleSubmit(updateItem)}>
              <Card.Subtitle className="mb-2 text-muted text-left">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="itemname"
                  type="text"
                  defaultValue={props.activeNode.itemname}
                  ref={register({
                    required: true,
                    maxLength: 50,
                    minLength: 1,
                  })}
                />
                <Form.Text className={errMsgTextStyle()}>
                  {watchName.length + "/50"}
                </Form.Text>
                <Form.Control
                  className="d-none"
                  name="itemindex"
                  type="text"
                  defaultValue={props.activeNode.index}
                  ref={register}
                />
                <Form.Control
                  className="d-none"
                  name="olditemname"
                  type="text"
                  defaultValue={props.activeNode.itemname}
                  ref={register}
                />
                <Form.Control
                  className="d-none"
                  name="oldprocessname"
                  type="text"
                  defaultValue={props.activeNode.process}
                  ref={register}
                />
                <Form.Control
                  className="d-none"
                  name="itemtype"
                  type="text"
                  defaultValue={props.activeNode.itemtype}
                  ref={register}
                />
                <Form.Control
                  className="d-none"
                  name="itemid"
                  type="text"
                  defaultValue={activeItemId}
                  ref={register}
                />
              </Card.Subtitle>
              {processInputDisplay(props.activeNode)}
              <Form.Text className="text-danger">{errMsgs}</Form.Text>
              <Card.Subtitle className="mb-2 text-muted text-left">
                Type: {props.activeNode.itemtype}
              </Card.Subtitle>
              <Button
                variant="outline-primary"
                type="submit"
                className="mx-1 btn-sm"
              >
                Save
              </Button>
              <Button
                variant="outline-danger"
                className="mx-1 btn-sm"
                onClick={() => (setEditMode(false), setErrMsgs())}
              >
                Cancel
              </Button>
            </Form>
          </Card.Body>
        </Card>
        {props.children}
      </div>
    );
  }
}

export default NodeCard;
