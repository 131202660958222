import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CollectionLoader from "../loaders/collectionLoader";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";

function UserPubSharedNetworkTbl(props) {
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([
    {
      network_id: "",
      title: "",
      description: "",
      tags: "",
      last_updated: "",
      created: "",
      actions: "",
    },
  ]);
  const username = props.username;

  //Create axios instance
  const axiosNetworks = axios.create();
  // Add a request interceptor
  axiosNetworks.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosNetworks.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  async function getUserNetworks() {
    const getUserNetworksApi = {
      url: "public-share/get-user-networks-meta/" + username,
      method: "GET",
    };
    await axiosNetworks(getUserNetworksApi)
      .then(async (response) => {
        setNetworks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function deleteNetwork(network) {
    const removeNetworkApi = {
      url: "public-share/delete-network",
      method: "POST",
      headers: {
        token: localStorage.token,
      },
      data: { network_id: network },
    };
    await axiosNetworks(removeNetworkApi)
      .then(async (response) => {
        await getUserNetworks();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getUserNetworks();
  }, []);

  function formatDateTime(timestamp) {
    const dbDateValue = new Date(timestamp).toLocaleDateString();
    const dbTimeValue = new Date(timestamp).toLocaleTimeString([], {
      timeStyle: "short",
    });
    return dbDateValue + " at " + dbTimeValue;
  }

  const tableRows = networks.map((network) => {
    formatDateTime(network.last_updated);
    return (
      <tr key={network.network_id}>
        <td>{network.network_id}</td>
        <td>{network.title}</td>
        <td>{network.description}</td>
        <td>{network.tags}</td>
        <td>{formatDateTime(network.last_updated)}</td>
        <td>{formatDateTime(network.created)}</td>
        <td>
          <Link target="_blank" to={"public-shared/" + network.network_id}>
            <Button
              variant="outline-primary"
              type="submit"
              className="mx-1 btn-sm"
            >
              Open
            </Button>
          </Link>
          <Button
            type="submit"
            variant="outline-danger"
            className="mx-1 btn-sm"
            onClick={() => deleteNetwork(network.network_id)}
          >
            Delete
          </Button>
        </td>
      </tr>
    );
  });

  if (!loading) {
    return (
      <Table responsive bordered size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Tags</th>
            <th>Last Updated</th>
            <th>Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
    );
  } else {
    return <CollectionLoader />;
  }
}

export default UserPubSharedNetworkTbl;
