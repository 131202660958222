import React from "react";
import css from "@emotion/css/macro";
import GridLoader from "react-spinners/GridLoader";
import Container from "react-bootstrap/Container";

function LoginRegisterLoader(props) {
  return (
    <Container>
      <div className="d-flex justify-content-center my-3">
        <GridLoader color={"#266e9b"} size={30} />
      </div>
    </Container>
  );
}

export default LoginRegisterLoader;
