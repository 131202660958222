import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import FilteredGraphData from "../dataUtils/filteredGraphData";
import Container from "react-bootstrap/Container";
import CollectionLoader from "../loaders/collectionLoader";
import BpForceDirectedGraph from "../graphs/bpForceDirected";

function MainGraphContainer(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { loading, processes, nodeData, linkData } = useContext(PmContext);

  function idPreface(item, idx) {
    if (item.itemtype === "activity") {
      return "act_" + idx;
    }
    if (item.itemtype === "object") {
      return "obj_" + idx;
    }
    if (item.itemtype === "role") {
      return "rle_" + idx;
    }
  }

  function getActivityProcess(item) {
    if (item.itemtype === "activity") {
      return item.process.label;
    }
    if (item.itemtype === "object" || item.itemtype === "role") {
      return "";
    }
  }

  const formattedNodeData = () => {
    if (!isAuthenticated) {
      return nodeData.map((node, index) => {
        return {
          itemid: idPreface(node, index),
          itemname: node.itemname,
          itemtype: node.itemtype,
          process: getActivityProcess(node),
        };
      });
    } else {
      return nodeData.map((node, index) => {
        return {
          itemid: node.item_id,
          itemname: node.item_name,
          itemtype: node.item_type,
          process: node.process,
        };
      });
    }
  };

  const formattedLinkData = () => {
    if (!isAuthenticated) {
      return linkData.map((link, index) => {
        return {
          source: link.link_source_id,
          linkSourceName: link.link_source_name,
          target: link.link_target_id,
          linkTargetName: link.link_target_name,
          type: link.link_type,
        };
      });
    } else {
      return linkData.map((link) => {
        return {
          source: link.link_source_id,
          linkSourceName: link.link_source_name,
          target: link.link_target_id,
          linkTargetName: link.link_target_name,
          type: link.link_type,
        };
      });
    }
  };

  if (!loading) {
    return (
      <Container fluid className="p-0">
        <div
          className="shadow-sm bg-white 
                        mx-lg-5
                        mx-sm-2 
                        mx-2
                         mb-1 main-graph-container"
        >
          <BpForceDirectedGraph
            className="h-100 w-100"
            nodeData={
              FilteredGraphData(
                formattedNodeData(),
                formattedLinkData(),
                props.isoActiveProcess
              ).nodes
            }
            linkData={
              FilteredGraphData(
                formattedNodeData(),
                formattedLinkData(),
                props.isoActiveProcess
              ).links
            }
            processes={processes}
            setActiveNode={props.setActiveNode}
            activeProcess={props.activeProcess}
            setActiveProcess={props.setActiveProcess}
            isoActiveProcess={props.isoActiveProcess}
            linkSpacing={props.linkSpacing}
          />
        </div>
      </Container>
    );
  } else {
    return (
      <React.Fragment>
        <CollectionLoader />
      </React.Fragment>
    );
  }
}

export default React.memo(MainGraphContainer);
