import React from "react";
import Container from "react-bootstrap/Container";
import Header from "./header";

function MainContainer(props) {
  return (
    <Container fluid className="h-100 p-0">
      <div className="mainContRow h-100">
        <Header setActiveNode={props.setActiveNode} />
        {props.children}
      </div>
    </Container>
  );
}

export default MainContainer;
