import React, { useState, useContext, useEffect, useMemo } from "react";
import axios from "axios";
import PublicShareNetworkSelect from "./publicShareNetworkSelect";
import Form from "react-bootstrap/Form";
import AuthContext from "../context/authContext";
import FilteredGraphData from "../dataUtils/filteredGraphData";
import { useForm, Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PmContext from "../context/pmContext";

function PublicShareForm(props) {
  const { name } = useContext(AuthContext);
  const { nodeData, linkData } = useContext(PmContext);
  const [loading, setLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [networkTitle, setNetworkTitle] = useState("");
  const [networkDesc, setNetworkDesc] = useState("");
  const [allTags, setAllTags] = useState();
  const [networkTags, setNetworkTags] = useState([]);
  const [oldNetworkTags, setOldNetworkTags] = useState([]);

  const formattedNodeData = () => {
    return nodeData.map((node, index) => {
      return {
        itemid: node.item_id,
        itemname: node.item_name,
        itemtype: node.item_type,
        process: node.process,
      };
    });
  };
  const formattedLinkData = () => {
    return linkData.map((link) => {
      return {
        source: link.link_source_id,
        linkSourceName: link.link_source_name,
        target: link.link_target_id,
        linkTargetName: link.link_target_name,
        type: link.link_type,
      };
    });
  };
  const nodes = JSON.stringify(
    FilteredGraphData(
      formattedNodeData(),
      formattedLinkData(),
      props.isoActiveProcess
    ).nodes
  );
  const links = JSON.stringify(
    FilteredGraphData(
      formattedNodeData(),
      formattedLinkData(),
      props.isoActiveProcess
    ).links
  );

  const {
    register,
    handleSubmit,
    reset,
    control,
    errors,
    watch,
    onChange,
    setValue,
  } = useForm();

  //form validation: title - 50, description - 280
  const watchTitle = watch("title", "");
  const errMsgTextStyle = () => {
    if (watchTitle.length > 50) {
      return "text-danger";
    }
  };
  const watchDesc = watch("description", "");
  const errMsgDescTextStyle = () => {
    if (watchDesc.length > 280) {
      return "text-danger";
    }
  };

  //Create axios instance
  const axiosSharedNetwork = axios.create();

  // Add a request interceptor
  axiosSharedNetwork.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosSharedNetwork.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  async function getTags() {
    const getAllTagsApi = {
      url: "/public-share/get-all-tags",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "GET",
    };
    await axiosSharedNetwork(getAllTagsApi)
      .then((response) => {
        const resData = response.data;
        const tags = resData.map((tag, index) => ({
          key: index,
          value: tag.tag_id,
          label: tag.name,
        }));
        setAllTags(tags);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function getNetworkData(networkId) {
    const getNetworkDataApi = {
      url: "/public-share/get-network/" + networkId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "GET",
    };
    const getNetworkTagsApi = {
      url: "/public-share/get-network-tags/" + networkId,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      method: "GET",
    };
    await axiosSharedNetwork(getNetworkDataApi)
      .then(async (response) => {
        const resData = response.data;
        setNetworkTitle(resData.title);
        setNetworkDesc(resData.description);
        await axiosSharedNetwork(getNetworkTagsApi)
          .then(async (response) => {
            const tagResData = response.data;
            if (Array.isArray(tagResData)) {
              const tags = tagResData.map((tag, index) => ({
                key: index,
                value: tag.tag_id,
                label: tag.name,
              }));
              setNetworkTags(tags);
              setOldNetworkTags(tags);
              reset({ tags });
            } else {
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getTags();
    getNetworkData(selectedNetwork);
    reset({ tags: selectedNetwork });
  }, [reset]);

  function resetTags() {
    reset({ tags: selectedNetwork });
  }

  async function onSubmit(data) {
    const apiAddNetwork = {
      url: "public-share/create-network",
      method: "POST",
      headers: {
        token: localStorage.token,
      },
      data: data,
    };
    const apiAddNetworkVersion = {
      url: "public-share/create-network-version",
      method: "POST",
      headers: {
        token: localStorage.token,
      },
      data: data,
    };
    const apiProcessTags = {
      url: "public-share/add-tags-to-network",
      method: "POST",
      headers: {
        token: localStorage.token,
      },
      data: data,
    };
    //If not existing, add network and version
    if (!data.network) {
      await axiosSharedNetwork(apiAddNetwork)
        .then(async (response) => {
          const networkId = response.data.network_id;
          data.network_id = networkId;
          await axiosSharedNetwork(apiProcessTags)
            .then(async (response) => {
              await axiosSharedNetwork(apiAddNetworkVersion)
                .then(async (response) => {
                  props.setShareLink("public-shared/" + networkId);
                  props.setPageCreated(true);
                })
                .catch((error) => {
                  console.log(error.response);
                });
            })
            .catch((error) => {
              console.log(error.response);
            });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
    //If existing, add version
    else {
      data.network_id = data.network;
      let addedTags = [];
      let removedTags = [];
      await axiosSharedNetwork(apiAddNetworkVersion)
        .then(async (response) => {
          //Need to check tags - if existing: ignore, if new: add, if removed: remove
          data.tags.forEach((tag) => {
            if (oldNetworkTags.includes(tag)) {
              //Do nothing, relationship already exists
            } else {
              addedTags.push(tag);
            }
          });
          oldNetworkTags.forEach((tag) => {
            if (!data.tags.includes(tag)) {
              removedTags.push(tag);
            } else {
            }
          });
          data.tags = addedTags;
          data.removed_tags = removedTags;
          await axiosSharedNetwork(apiProcessTags);
          props.setShareLink("public-shared/" + data.network_id);
          props.setPageCreated(true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }

  return (
    <Form id="public-share-form" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label>Create New Version of Existing Shared Network?</Form.Label>
        <PublicShareNetworkSelect
          username={name}
          control={control}
          setValue={setValue}
          resetTags={resetTags}
          onChange={onChange}
          setSelectedNetwork={setSelectedNetwork}
          selectedNetwork={selectedNetwork}
          getNetworkData={getNetworkData}
          setNetworkTitle={setNetworkTitle}
          setNetworkDesc={setNetworkDesc}
        />
        <Form.Label>Title</Form.Label>
        <Form.Control
          required
          as="input"
          name="title"
          defaultValue={networkTitle}
          ref={register({
            minLength: 1,
            maxLength: 50,
          })}
        ></Form.Control>
        <Form.Text className={errMsgTextStyle()}>
          {watchTitle.length + "/50"}
        </Form.Text>
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          name="description"
          defaultValue={networkDesc}
          ref={register({
            minLength: 0,
            maxLength: 280,
          })}
        ></Form.Control>
        <Form.Text className={errMsgDescTextStyle()}>
          {watchDesc.length + "/280"}
        </Form.Text>
        <Form.Control
          className="d-none"
          name="node_data"
          type="text"
          defaultValue={nodes}
          ref={register}
        />
        <Form.Control
          className="d-none"
          name="link_data"
          type="text"
          defaultValue={links}
          ref={register}
        />
        <Form.Label>Tags</Form.Label>
        <Controller
          name="tags"
          defaultValue={[]}
          control={control}
          render={(props) => (
            <CreatableSelect
              isMulti
              placeholder={"Select existing or create new..."}
              onChange={(e) => onChange(e)}
              options={allTags}
              classNamePrefix="select"
              {...props}
            />
          )}
        />
        <Form.Control
          className="d-none"
          name="username"
          type="text"
          defaultValue={name}
          ref={register}
        />
      </Form.Group>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          Share
        </Button>
      </Modal.Footer>
    </Form>
  );
}

export default PublicShareForm;
