import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import "../../custom_styles/bpForceDirected.css";

export const BpForceDirectedGraph = (props) => {
  const d3Container = useRef(null);
  useEffect(() => {
    if (props.nodeData && d3Container.current) {
      //Define container
      var w = d3Container.current.getBoundingClientRect().width;
      var h = d3Container.current.getBoundingClientRect().height;
      var radius = 15;
      const svg = d3
        .select(d3Container.current)
        .html("")
        .attr("id", "container-svg")
        .attr("viewBox", "0 0 " + w + " " + h)
        .attr("preserveAspectRatio", "xMidYMid meet");

      //-----Core graph setup-----//
      //set up the simulation
      //nodes only for now
      var simulation = d3
        .forceSimulation()
        //add nodes
        .nodes(props.nodeData);

      //add forces
      //we're going to add a charge to each node
      //also going to add a centering force
      simulation
        .force("charge_force", d3.forceManyBody())
        .force("center_force", d3.forceCenter(w / 2, h / 2));

      var nodes = svg.append("g").attr("class", "nodes");

      //Create node to contain circle and label
      var node = nodes.selectAll("g").data(props.nodeData).enter().append("g");

      //Create circle
      node
        .append("circle")
        .attr("r", 10)
        .attr("fill", circleColor)
        .style("stroke-width", strokeMe)
        .style("stroke", "rgba(35, 31, 32, 0.1)");

      //Create label
      node
        .append("text")
        .attr("class", "nodelabel")
        .attr("dx", 12)
        .attr("dy", ".35em")
        .text(function (d) {
          return d.itemname;
        });

      //add tick instructions:
      simulation.on("tick", tickActions);

      //Create the link force
      //We need the id accessor to use named sources and targets

      var link_force = d3
        .forceLink(props.linkData)
        .distance(props.linkSpacing)
        .id(function (d) {
          return d.itemid;
        });

      //Add a links force to the simulation
      //Specify links  in d3.forceLink argument

      simulation.force("links", link_force);

      //draw lines for the links
      // var link = svg
      var link = nodes
        .append("g")
        .attr("class", "links")
        .selectAll("line")
        .data(props.linkData)
        .enter()
        .append("line")
        .attr("stroke-width", 1)
        .style("stroke", linkColor);

      function tickActions() {
        //update circle positions each tick of the simulation
        node
          .attr("transform", (d) => `translate(${d.x},${d.y})`)
          .attr("cx", function (d) {
            return (d.x = Math.max(radius, Math.min(w - radius, d.x)));
          })
          .attr("cy", function (d) {
            return (d.y = Math.max(radius, Math.min(h - radius, d.y)));
          });
        //update link positions
        //simply tells one end of the line to follow one node around
        //and the other end of the line to follow the other node around
        link
          .attr("x1", function (d) {
            return d.source.x;
          })
          .attr("y1", function (d) {
            return d.source.y;
          })
          .attr("x2", function (d) {
            return d.target.x;
          })
          .attr("y2", function (d) {
            return d.target.y;
          });
      }

      //-----Graph Interactions-----//
      //Node dragging
      var drag_handler = d3
        .drag()
        .on("start", drag_start)
        .on("drag", drag_drag)
        .on("end", drag_end);

      //same as using .call on the node variable as in https://bl.ocks.org/mbostock/4062045
      drag_handler(node);

      //drag handler
      //d is the node
      function drag_start(d) {
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function drag_drag(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }

      function drag_end(d) {
        if (!d3.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      //add zoom capabilities
      function zoom_actions() {
        nodes.attr("transform", d3.event.transform);
      }

      var zoom_handler = d3.zoom().on("zoom", zoom_actions);

      zoom_handler(svg);

      //Setting active nodes on click
      node.on("click", function (d) {
        props.setActiveNode(d);
      });

      //-----Styling Functions & Highlight/Isolate direction-----//
      //Function to choose what color circle we have
      function circleColor(d) {
        if (props.activeProcess.length > 0) {
          if (
            d.itemtype === "activity" &&
            props.activeProcess.includes(d.process)
          ) {
            return "rgba(0, 130, 206, 1)";
          } else if (d.itemtype === "activity") {
            return "rgba(0, 130, 206, .3)";
          } else if (
            d.process !== null &&
            props.activeProcess.some((i) => d.process.includes(i))
          ) {
            if (d.itemtype === "object") {
              return "rgba(100, 103, 138, 1)";
            }
            if (d.itemtype === "role") {
              return "rgba(0, 175, 185, 1)";
            }
          } else {
            if (d.itemtype === "object") {
              return "rgba(100, 103, 138, .3)";
            }
            if (d.itemtype === "role") {
              return "rgba(0, 175, 185, .3)";
            }
          }
        }
        if (props.activeProcess.length === 0) {
          if (d.itemtype === "activity") {
            return "rgba(0, 130, 206, 1)";
          }
        }
        if (d.itemtype === "role") {
          return "#00AFB9";
        }
        if (d.itemtype === "object") {
          return "#64678A";
        } else {
          return "red";
        }
      }

      //Function to add stroke to highlighted process nodes
      function strokeMe(d) {
        if (
          d.itemtype === "activity" &&
          props.activeProcess.includes(d.process)
        ) {
          return 5;
        } else if (
          d.process !== null &&
          d.process !== undefined &&
          props.activeProcess.some((i) => d.process.includes(i))
        ) {
          return 5;
        } else {
          return 0;
        }
      }

      //Functions to fade things on hover
      var linkedByIndex = {};
      props.linkData.forEach(function (d) {
        linkedByIndex[d.source.index + "," + d.target.index] = 1;
      });

      function isConnected(a, b) {
        return (
          linkedByIndex[a.index + "," + b.index] ||
          linkedByIndex[b.index + "," + a.index] ||
          a.index == b.index
        );
      }

      function fade(opacity) {
        return function (d) {
          node.style("stroke-opacity", function (o) {
            var thisOpacity = isConnected(d, o) ? 1 : opacity;
            this.setAttribute("fill-opacity", thisOpacity);
            return thisOpacity;
          });

          link.style("stroke-opacity", function (o) {
            return o.source === d || o.target === d ? 1 : opacity;
          });
        };
      }

      //Fade non-neighboring nodes on hover
      node.on("mouseover", fade(0.2)).on("mouseout", fade(1));

      //Generate line color
      function linkColor(d) {
        // if (d.type === "activity-object-output") {
        //   return "green";
        // } else {
        return "black";
        // }
      }

      // Remove old D3 elements
      node.exit().remove();
    }
  }, [
    d3Container.current,
    props.nodeData,
    props.linkData,
    props.activeProcess,
  ]);

  if (props.nodeData.length > 0) {
    return (
      <React.Fragment>
        <svg className="bp-force-directed h-100 w-100" ref={d3Container} />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <h3 className="pt-5 px-2">
          Begin by adding an activity, object, or role from the process modeling
          palette
        </h3>
      </React.Fragment>
    );
  }
};

export default BpForceDirectedGraph;
