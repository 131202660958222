import React, { useState, useEffect } from "react";
import axios from "axios";
import { Controller } from "react-hook-form";
import Select from "react-select";

function PublicShareNetworkSelect(props) {
  const [loading, setLoading] = useState(false);
  const [networks, setNetworks] = useState([]);
  //Create axios instance
  const axiosNetworks = axios.create();
  // Add a request interceptor
  axiosNetworks.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosNetworks.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  async function getNetworks() {
    const getNetworksApi = {
      url: "public-share/get-user-networks/" + props.username,
      method: "GET",
    };
    await axiosNetworks(getNetworksApi)
      .then(async (response) => {
        setNetworks(
          response.data.map((network, index) => ({
            key: index,
            value: network.network_id,
            label: network.title,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    getNetworks();
  }, []);

  function handleChange(data) {
    if (data) {
      props.setValue("network", data.value);
      props.setSelectedNetwork(data.value);
      props.getNetworkData(data.value);
    } else {
      props.setNetworkTitle("");
      props.setNetworkDesc("");
      props.resetTags();
    }
  }

  if (!loading) {
    if (networks.length === 0) {
      return (
        <React.Fragment>
          <br />
          <p className="font-italic text-muted">
            You haven't created any public networks yet.
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <Controller
          name="network"
          defaultValue={""}
          control={props.control}
          render={({ onChange }) => (
            <Select
              closeMenuOnSelect={true}
              isClearable={true}
              options={networks}
              noOptionsMessage={() => "No matching options"}
              defaultValue={""}
              onChange={(e) => handleChange(e)}
              className="basic-single"
              classNamePrefix="select"
            />
          )}
        />
      );
    }
  } else {
    return <React.Fragment>Loading...</React.Fragment>;
  }
}

export default PublicShareNetworkSelect;
