import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import { Redirect } from "react-router-dom";
import Login from "../pages/login";

function LoginRoutes(props) {
  const { isAuthenticated, setAuth } = useContext(AuthContext);

  return !isAuthenticated ? (
    <Login {...props} setAuth={setAuth} />
  ) : (
    <Redirect to="/visualizer" />
  );
}

export default LoginRoutes;
