import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import PmContext from "../context/pmContext";
import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import Select from "react-select";

function MultiSelectInput(props) {
  const { isAuthenticated } = useContext(AuthContext);
  const { nodeData } = useContext(PmContext);
  const items = props.items;
  const options = () => {
    if (!isAuthenticated) {
      if (props.isUpdate === "y") {
        //filter out activeNode item from lists
        if (
          props.relationshipName === "activitiesbefore" ||
          props.relationshipName === "activitiesafter" ||
          props.relationshipName === "activitiesusedin" ||
          props.relationshipName === "activitiesproducedin" ||
          props.relationshipName === "activitiesresponsible" ||
          props.relationshipName === "activitiessupport" ||
          props.relationshipName === "activitiesnotify"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "act_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter(
              (i) =>
                i.itemtype === "activity" &&
                i.label !== props.activeNode.itemname
            );
        }
        //If relationship to objects
        if (
          props.relationshipName === "objectsinput" ||
          props.relationshipName === "objectsoutput"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "obj_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter(
              (i) =>
                i.itemtype === "object" && i.label !== props.activeNode.itemname
            );
        }
        //If relationship to roles
        if (
          props.relationshipName === "roleresponsiblefor" ||
          props.relationshipName === "rolesupportin" ||
          props.relationshipName === "rolenotifiedin"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "rle_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter(
              (i) =>
                i.itemtype === "role" && i.label !== props.activeNode.itemname
            );
        }
      } else {
        //Pass in nodeData as items for all item types and generate lists by type
        // in this file so that nodeData index numbers can be used as ID in value key
        //If relationship to activities
        if (
          props.relationshipName === "activitiesbefore" ||
          props.relationshipName === "activitiesafter" ||
          props.relationshipName === "activitiesusedin" ||
          props.relationshipName === "activitiesproducedin" ||
          props.relationshipName === "activitiesresponsible" ||
          props.relationshipName === "activitiessupport" ||
          props.relationshipName === "activitiesnotify"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "act_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter((i) => i.itemtype === "activity");
        }
        //If relationship to objects
        if (
          props.relationshipName === "objectsinput" ||
          props.relationshipName === "objectsoutput"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "obj_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter((i) => i.itemtype === "object");
        }
        //If relationship to roles
        if (
          props.relationshipName === "roleresponsiblefor" ||
          props.relationshipName === "rolesupportin" ||
          props.relationshipName === "rolenotifiedin"
        ) {
          return nodeData
            .map((item, index) => ({
              key: index,
              value: "rle_" + index,
              label: item.itemname,
              itemtype: item.itemtype,
            }))
            .filter((i) => i.itemtype === "role");
        }
      }
    } else {
      if (
        props.relationshipName === "activitiesbefore" ||
        props.relationshipName === "activitiesafter" ||
        props.relationshipName === "activitiesusedin" ||
        props.relationshipName === "activitiesproducedin" ||
        props.relationshipName === "activitiesresponsible" ||
        props.relationshipName === "activitiessupport" ||
        props.relationshipName === "activitiesnotify"
      ) {
        return items.map((item, index) => ({
          key: index,
          value: item.activity_id,
          label: item.name,
        }));
      }
      if (
        props.relationshipName === "objectsinput" ||
        props.relationshipName === "objectsoutput"
      ) {
        return items.map((item, index) => ({
          key: index,
          value: item.object_id,
          label: item.name,
        }));
      }
      if (
        props.relationshipName === "roleresponsiblefor" ||
        props.relationshipName === "rolesupportin" ||
        props.relationshipName === "rolenotifiedin"
      ) {
        return items.map((item, index) => ({
          key: index,
          value: item.role_id,
          label: item.name,
        }));
      }
    }
  };

  return (
    <Form.Group controlId="" className="mx-2">
      <Form.Label>{props.relationshipLabel}</Form.Label>
      <Controller
        as={
          <Select
            isMulti
            closeMenuOnSelect={false}
            options={options()}
            defaultValue={props.defaultValues}
            name={props.relationshipName}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        }
        control={props.formCont}
        name={props.relationshipName}
        defaultValue={props.defaultValues}
      />
      {/* <Controller
        name={props.relationshipName}
        defaultValue={[]}
        control={props.formCont}
        render={({ value }) => {
          return (
            <Select
              isMulti
              valueName={value}
              closeMenuOnSelect={true}
              options={options()}
              defaultValue={[]}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          );
        }}
      /> */}
      <Controller
        as={
          <Select
            isMulti
            closeMenuOnSelect={false}
            options={options()}
            defaultValue={props.defaultValues}
            name="initialvalues"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        }
        control={props.formCont}
        className="d-none"
        name="initialvalues"
        defaultValue={props.defaultValues}
      />
    </Form.Group>
  );
}

export default MultiSelectInput;
