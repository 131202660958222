import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import CenterBoxContainer from "../layout/centerBoxContainer";
import LoginRegisterLoader from "../loaders/loginRegisterLoader";

function Login(props) {
  document.title = "bpviz | login";
  ReactGA.initialize("UA-181847035-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const { register, handleSubmit } = useForm({});
  const [googleUrl, setGoogleUrl] = useState();
  const [errMsgs, setErrMsgs] = useState([]);
  const [loading, setLoading] = useState(false);

  //Get code from Google redirect
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let googleCode = useQuery().get("code");

  //Create axios instance
  const axiosLogin = axios.create();

  // Add a request interceptor
  axiosLogin.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      setLoading(true);
      return config;
    },
    function (error) {
      // Do something with request error
      setLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosLogin.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      setLoading(true);
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      setLoading(false);
      return Promise.reject(error);
    }
  );
  const loginUser = (data) => {
    const apiOptions = {
      url: "/users/login",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: data,
    };
    axios(apiOptions)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        props.setAuth(true);
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsgs(error.response.data);
      });
  };

  async function googleLogin() {
    const apiGoogleAuth = {
      url: "/users/google-auth",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: { googleCode },
    };
    axios(apiGoogleAuth)
      .then(async (response) => {
        //If account exists
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          props.setAuth(true);
        }
        //If new google user
        else {
          const userId = response.data.userId;
          const name = response.data.name;
          const apiCreateClient = {
            url: "/client/create-client",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
            data: { userId, name },
          };
          //create client
          await axios(apiCreateClient).then(async (response) => {
            const apiAddUserToClient = {
              url: "/users/add-to-client",
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
              },
              data: { userId },
            };
            //assign user to client
            await axios(apiAddUserToClient).then(async (response) => {
              //Get client ID and assign token
              const clientId = response.data.clientId;
              localStorage.setItem("token", response.data.token);
              const apiCreateClientSchema = {
                url: "/client/create-client-schema",
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json;charset=UTF-8",
                },
                data: { clientId },
              };
              apiCreateClientSchema.headers.token = response.data.token;
              //create schema for client
              await axios(apiCreateClientSchema);
              await props.setAuth(true);
            });
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsgs(error.response.data);
      });
  }

  async function getGoogleUrl() {
    const apiGoogleUser = {
      url: "/users/get-google-url",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      // data: data,
    };
    await axios(apiGoogleUser)
      .then(async (req, res) => {
        setGoogleUrl(req.data);
        // console.log(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        // setErrMsgs(error.response);
      });
  }

  useEffect(() => {
    getGoogleUrl();
  }, []);

  if (!loading) {
    //If redirect from Google
    if (googleCode) {
      googleLogin();
      return "Logging in via Google...";
    }
    //If local user
    else {
      return (
        <CenterBoxContainer>
          <div className="border-bottom">
            <Button
              variant="outline-secondary"
              className="mx-5 my-3"
              href={googleUrl}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-google mx-2"
                viewBox="0 0 16 16"
              >
                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
              </svg>
              Login with Google
            </Button>
          </div>
          <Form className="p-4" onSubmit={handleSubmit(loginUser)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                ref={register}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Password"
                ref={register}
              />
            </Form.Group>
            <Button variant="primary" type="submit" variant="spotlight">
              Login
            </Button>
            <Form.Text className="text-left text-danger">{errMsgs}</Form.Text>
            <Form.Text className="text-muted">
              Not a member yet? <Link to="/register">Register</Link>
            </Form.Text>
          </Form>
        </CenterBoxContainer>
      );
    }
  } else {
    return (
      <CenterBoxContainer>
        <LoginRegisterLoader />
      </CenterBoxContainer>
    );
  }
}

export default Login;
